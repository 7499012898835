import React, { useState, useRef } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import ModalCambiarColectivaComponent from '../Modal/ModalCambiarColectiva/modalCambiar.component';
import ModalCambiarColectivaSuccessComponent from '../Modal/ModalCambiarColectiva/modalCambiarSuccess.component';
import ModalDisabledComponent from '../Modal/ModalDisabled/modalDisabled.component';
import ModalDisabledSuccessComponent from '../Modal/ModalDisabled/modalDisabledSuccess.component';
import ModalDeleteComponent from '../Modal/ModalDelete/modalDelete.component';
import ModalDeleteSuccessComponent from '../Modal/ModalDelete/modalDeleteSuccess.component';
import {
  AdminPrincipalRol,
  AdminStatus,
  IAdmin,
} from '../../../types/adminInterface';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

type ActionsColectivaButtonComponentProps = {
  admin: Partial<IAdmin>;
  saveAdmin: (admin: Partial<IAdmin>, adminId: string) => Promise<void>;
};

const ActionsColectivaButtonComponent: React.FC<
  ActionsColectivaButtonComponentProps
> = ({ admin, saveAdmin }) => {
  const [show, setShow] = useState(false);
  const [modalShowCambiar, setModalShowCambiar] = useState(false);
  const [modalShowCambiarSuccess, setModalShowCambiarSuccess] = useState(false);
  const [modalShowDisabled, setModalShowDisabled] = useState(false);
  const [modalShowDisabledOK, setModalShowDisabledOK] = useState(false);
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const [modalShowDeleteSuccess, setModalShowDeleteSuccess] = useState(false);

  const navigate = useNavigate();
  const target = useRef(null);

  const handleAdminDeactivate = () => {
    setShow(false);
    if (admin.status === AdminStatus.ACTIVE) {
      Swal.fire({
        title: 'Desactivar temporalmente',
        text: '¿Deseas desactivar temporalmente esta cuenta?',
        html: `<p className="text-center mb-5">
          Necesitamos tu confirmación, recuerda que puedes volver a activar esta cuenta cuando lo desees.
        </p>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, desactivar',
        cancelButtonText: 'Cancelar',
      }).then((resp) => {
        if (resp.isConfirmed) {
          saveAdmin({ status: AdminStatus.INACTIVE }, admin._id || '').then(
            () => {
              Swal.fire({
                title: '¡Cuenta desactivada!',
                text: 'La cuenta ha sido desactivada temporalmente',
                icon: 'success',
              });
            }
          );
        }
      });
    }
    if (admin.status === AdminStatus.INACTIVE) {
      Swal.fire({
        title: 'Activar cuenta',
        text: '¿Deseas activar esta cuenta?',
        html: `<p className="text-center mb-5">
          Necesitamos tu confirmación, recuerda que puedes volver a desactivar esta cuenta cuando lo desees.
        </p>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, activar',
        cancelButtonText: 'Cancelar',
      }).then((resp) => {
        if (resp.isConfirmed) {
          saveAdmin({ status: AdminStatus.ACTIVE }, admin._id || '').then(
            () => {
              Swal.fire({
                title: '¡Cuenta activada!',
                text: 'La cuenta ha sido activada',
                icon: 'success',
              });
            }
          );
        }
      });
    }
  };

  const handleAdminDelete = () => {
    setShow(false);
    Swal.fire({
      title: 'Eliminar cuenta',
      text: '¿Deseas eliminar esta cuenta?',
      html: `<p className="text-center mb-5">
        Necesitamos tu confirmación, recuerda que no podrás recuperar esta cuenta.
      </p>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    }).then((resp) => {
      if (resp.isConfirmed) {
        saveAdmin({ status: AdminStatus.DELETED }, admin._id || '').then(() => {
          Swal.fire({
            title: '¡Cuenta eliminada!',
            text: 'La cuenta ha sido eliminada',
            icon: 'success',
          }).then(() => {
            const { colectiva } = admin;
            navigate(`/mi-colectiva/${colectiva?.id}`);
          });
        });
      }
    });
  };

  return (
    <>
      <ModalCambiarColectivaComponent
        show={modalShowCambiar}
        onHide={() => setModalShowCambiar(false)}
        onSuccess={(result) => {
          const colectiva = {
            id: Number(result?.colectiva?.documentId || 0),
            name: result?.colectiva?.value || '',
          };
          saveAdmin({ colectiva }, admin._id || '').then(() => {
            setModalShowCambiar(false);
            setModalShowCambiarSuccess(true);
          });
        }}
        user={{
          _id: admin._id,
          username: admin.username,
          colectiva: {
            documentId: `${admin.colectiva?.id || ''}`,
            value: admin.colectiva?.name,
          },
        }}
      />
      <ModalCambiarColectivaSuccessComponent
        show={modalShowCambiarSuccess}
        onHide={() => {
          setModalShowCambiarSuccess(false);
          setShow(false);
        }}
        user={{
          _id: admin._id,
          username: admin.username,
          userId: admin.adminId,
          colectiva: {
            documentId: `${admin.colectiva?.id || ''}`,
            value: admin.colectiva?.name,
          },
        }}
      />
      <ModalDisabledComponent
        show={modalShowDisabled}
        onHide={() => setModalShowDisabled(false)}
        onSuccess={() => {
          setModalShowDisabled(false);
          setModalShowDisabledOK(true);
        }}
        user={{}}
      />
      <ModalDisabledSuccessComponent
        user={{}}
        show={modalShowDisabledOK}
        onHide={() => {
          setModalShowDisabledOK(false);
          setShow(false);
        }}
      />
      <ModalDeleteComponent
        show={modalShowDelete}
        onHide={() => setModalShowDelete(false)}
        onSuccess={() => {
          setModalShowDelete(false);
          setModalShowDeleteSuccess(true);
        }}
      />
      <ModalDeleteSuccessComponent
        show={modalShowDeleteSuccess}
        onHide={() => {
          setModalShowDeleteSuccess(false);
          setShow(false);
        }}
      />
      <button
        type="button"
        className="btn btn--type3 btn--100"
        ref={target}
        onClick={() => setShow(!show)}
      >
        + Más acciones
      </button>
      <Overlay
        target={target.current}
        show={show}
        placement={window.innerWidth < 768 ? 'bottom' : 'left'}
        rootClose
        onHide={() => {
          setShow(false);
        }}
      >
        <Tooltip className="tooltip--white">
          <ul className="tooltip-options">
            <li>
              <Link to={`/editar-administrador/${admin._id}`}>
                <i className="icon icon--edit"></i>
                Editar información
              </Link>
            </li>
            <li>
              <button
                type="button"
                onClick={() => {
                  setModalShowCambiar(true);
                  setShow(false);
                }}
              >
                <i className="icon icon--change"></i>
                Cambiar a otra colectiva
              </button>
            </li>
            {[
              AdminPrincipalRol.ACOMPANANTE,
              AdminPrincipalRol.TALLERISTA,
              AdminPrincipalRol.ENTREGADORA,
            ].some((rol) =>
              [admin.principalRol, admin.secondaryRol].includes(rol)
            ) && (
              <li>
                <Link to={`/evaluaciones/${admin._id}`}>
                  <i className="icon icon--evaluaciones"></i>
                  Evaluaciones
                </Link>
              </li>
            )}
            {[admin.principalRol, admin.secondaryRol].includes(
              AdminPrincipalRol.TALLERISTA
            ) && (
              <li>
                <Link to={`/talleres/${admin._id}`}>
                  <i className="icon icon--evaluaciones"></i>
                  Talleres
                </Link>
              </li>
            )}
            <li>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleAdminDeactivate();
                }}
              >
                {admin.status === AdminStatus.ACTIVE ? (
                  <>
                    <i className="icon icon--remove"></i>
                    Desactivar cuenta
                  </>
                ) : (
                  <>
                    <i className="icon icon--add"></i>
                    Activar cuenta
                  </>
                )}
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleAdminDelete();
                }}
              >
                <i className="icon icon--delete"></i>
                <span className="text-red">Eliminar cuenta</span>
              </button>
            </li>
          </ul>
        </Tooltip>
      </Overlay>
    </>
  );
};

export default ActionsColectivaButtonComponent;
