import React from 'react';
import { IUser, IUserPaginate } from '../../../types/userInterface';
import { format } from 'date-fns';

interface WomenComponentProps {
  users: IUserPaginate;
}

const WomenComponent: React.FC<WomenComponentProps> = ({ users }) => {
  const lastRecord: Partial<IUser> = users.docs.length > 0 ? users.docs[0] : {};

  return (
    <div className="col-12 col-reset">
      <p className="text-64 text-500 mb-0">{users.totalDocs || 0}</p>
      <p className="text-24 text-titular text-700 mb-0">
        Mujeres atendidas hasta hoy
      </p>
      <p>
        Último registro:{' '}
        {format(new Date(lastRecord.createdAt || ''), 'dd MMMM yyyy')}
      </p>
    </div>
  );
};

export default WomenComponent;
