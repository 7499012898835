import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { io, Socket } from 'socket.io-client';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import NotificationsComponent from '../../../../components/Elements/Notifications/notifications.component';
import TagsManagerComponent from '../../../../components/Forms/tagsManager/tagsManager.component';
import NotesComponent from '../../../../components/Elements/Notes/notes.component';
import TagsComponent from '../../../../components/Forms/tags/tags.component';
import { TAGS_DATA } from '../../../../utils/tags';
import ModalProcessComponents from '../../../../components/Elements/Modal/ModalProcess/modalProcess.component';
import ModalTallerComponents from '../../../../components/Elements/Modal/ModalTaller/modalTaller.component';
import PersonalDataComponent from '../../../../components/Forms/userDetail/personalData.component';
import DemographicDataComponent from '../../../../components/Forms/userDetail/demographicData.component';
import HealthDataComponent from '../../../../components/Forms/userDetail/healthData.component';
import InfoDataComponent from '../../../../components/Forms/userDetail/infoData.component';
import ActionsButtonComponent from '../../../../components/Elements/Buttons/actions.component';
import { getSession } from '../../../../utils/helpers';
import { getUser, updateUser } from '../../../../api/usersApi';
import {
  IUser,
  UserSource,
  UserStatus,
  UserSteps,
} from '../../../../types/userInterface';
import LoaderComponent from '../../../../components/Loader/loader.component';
import ChatModalComponent from '../../../../components/Chat/chatModal.component';
import { AdminPrincipalRol, IAdmin } from '../../../../types/adminInterface';
import { fetchChatMessages } from '../../../../api/chatApi';
import { MessageBoxType } from 'react-chat-elements';
import ModalAsignarComponent from '../../../../components/Elements/Modal/ModalAsignar/modalAsignar.component';
import {
  IWorkshop,
  IWorkshopAssignedUserStatus,
} from '../../../../types/workshopInterface';

const DetailUserScreen: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [session, setSession] = useState<Partial<IAdmin>>({});
  const [messages, setMessages] = useState<MessageBoxType[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowTaller, setModalShowTaller] = useState(false);
  const [modalShowChat, setModalShowChat] = useState(false);
  const [modalShowAsignar, setModalShowAsignar] = useState(false);
  const [tagsShow, setTagsShow] = useState<string[]>([]);
  const [socket, setSocket] = useState<Socket>();
  const tagsFiltered = TAGS_DATA.filter((tag) => tagsShow.includes(tag.id));

  const { id } = useParams<{ id: string }>();

  const setError = (message: string) => {
    console.error(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const handleShowModal = () => {
    setModalShow(true);
  };

  const handleCloseModalProcessAndOpenModalTaller = () => {
    setModalShow(false); // Cierra ModalProcessComponents
    setModalShowTaller(true); // Abre ModalTallerComponents
  };

  const fetchOldMessages = async (
    roomId: string,
    token: string,
    userId: string
  ) => {
    try {
      setLoading(true);
      const response = await fetchChatMessages(roomId, token);

      const messages = response.docs.reverse().map((doc) => {
        const message: MessageBoxType = {
          id: doc._id || '',
          position: doc.sender === userId ? 'right' : 'left',
          type: 'text',
          text: doc.message,
          date: new Date(doc.createdAt || ''),
          avatar:
            doc.sender === userId
              ? '/assets/images/icons/user-small.svg'
              : '/assets/images/icons/user-pic-2.svg',
          title:
            doc.sender === userId ? session.username || 'Tu' : doc.senderName,
          focus: false,
          forwarded: false,
          replyButton: false,
          removeButton: false,
          titleColor: '#000000',
          status: doc.sender === userId ? 'sent' : 'received',
          notch: true,
          retracted: false,
        };
        return message;
      });

      setMessages([...messages]);
    } catch (error) {
      console.error('Error fetching old messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUser = async () => {
    try {
      setLoading(true);
      if (!id) {
        throw new Error('No se pudo obtener el id del usuario');
      }
      const session = getSession();
      const response = await getUser(id, session?.token || '');

      if (session) {
        setSession(session);
      }
      if (response.status === UserStatus.BLOCKED) {
        navigate('/mi-colectiva');
      }
      setUser(response);
    } catch (error) {
      setError('Error al obtener la información del usuario');
    } finally {
      setLoading(false);
    }
  };

  const updateAmiga = async (updatedUser: Partial<IUser>) => {
    try {
      setLoading(true);
      const session = getSession();
      const response = await updateUser(
        updatedUser._id || '',
        updatedUser,
        session?.token || ''
      );
      setUser(response);
    } catch (error) {
      setError('Error al actualizar el usuario');
    } finally {
      setLoading(false);
    }
  };

  const setNewMessage = (msg: MessageBoxType) => {
    setMessages((prevMessages) => [...prevMessages, msg]);
    // scroll to the bottom of the chat element with class .rce-mlist
    setTimeout(() => {
      const chatContainer = document.querySelector('.rce-mlist');
      if (chatContainer) {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight + 200,
          behavior: 'smooth',
        });
      }
    }, 300);
  };

  const determineTagsToShow = (user: Partial<IUser>) => {
    const tags: string[] = [];
    const userSource = user.derivada?.derivadaA || '';
    const violentometro = user.violentometro?.niveles || [];

    if (userSource === UserSource.OLA) {
      tags.push('tag-1'); // DERIVADA DE OLA
    }
    if (userSource === 'oirs') {
      tags.push('tag-3'); // CUENTA ACTUALIZADA
    }
    if ((user?.datosSociodemograficos?.currentAge || 99) <= 18) {
      tags.push('tag-4'); // ES MENOR DE EDAD
    }
    if (violentometro.some((level) => level.id >= 10)) {
      tags.push('tag-5'); // Situación de violencia
    }
    if (user?.status === UserStatus.ACTIVE) {
      tags.push('tag-10'); // Cuenta activa
    }
    if (user?.status === UserStatus.INACTIVE) {
      tags.push('tag-11'); // CUENTA DESACTIVADA TEMPORALMENTE
    }
    setTagsShow(tags);
  };

  const getSocket = async () => {
    const socket = io('https://chat.conlasamigas.org/');
    socket.on('connect', () => {
      console.log('Connected to server');
    });
    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });
    setSocket(socket);
  };

  const notAttendedToWorkshop = (user: Partial<IUser>) => {
    const { workshopSelected } = user;
    if (workshopSelected && workshopSelected.workshopId) {
      const workshop = workshopSelected.workshopId as IWorkshop;
      if (workshop && workshop._id) {
        const { assignedUsers } = workshop;
        if (assignedUsers && assignedUsers.length) {
          const assignedUser = assignedUsers.find(
            (aU) => aU.userId === user._id
          );
          if (assignedUser) {
            const attended =
              assignedUser.status === IWorkshopAssignedUserStatus.NOT_ATTENDED;
            console.log('attendedToWorkshop', { assignedUser, attended });
            return attended;
          }
        }
      }
    }
    return false;
  };

  const getChatRoomId = () => {
    const entregadoraRol = AdminPrincipalRol.ENTREGADORA;
    if (
      session.principalRol === entregadoraRol ||
      session.secondaryRol === entregadoraRol
    ) {
      if (user.step === UserSteps.ENTREGA) {
        return user.assignedDeliverer?.chatRoomId || '';
      }
    }
    if (session.principalRol === AdminPrincipalRol.ADMIN) {
      if (user.step === UserSteps.ENTREGA) {
        return user.assignedDeliverer?.chatRoomId || '';
      }
    }
    return user.assignedTo?.chatRoomId || '';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUser();
  }, []);

  useEffect(() => {
    determineTagsToShow(user);
  }, [user]);

  useEffect(() => {
    getSocket();
  }, []);

  return (
    <>
      <ModalProcessComponents
        user={user}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onShowTaller={handleCloseModalProcessAndOpenModalTaller}
        setModalShowChat={() => {
          const roomId = getChatRoomId();
          const token = session.token || '';
          const userId = session._id || '';
          fetchOldMessages(roomId, token, userId).then(() =>
            setModalShowChat(true)
          );
        }}
        setModalShowAsignar={setModalShowAsignar}
      />
      {modalShowTaller && (
        <ModalTallerComponents
          show={modalShowTaller}
          onHide={() => setModalShowTaller(false)}
          headerTitle="Seleccionar talleres"
          headerSubtitle="Selecciona entre los talleres disponibles a ofrecer"
          showSubmitButton={true}
          setLoading={setLoading}
          loading={loading}
          user={user}
        />
      )}
      {user && socket && (
        <ChatModalComponent
          admin={session}
          roomId={getChatRoomId()}
          token={session?.token || ''}
          show={modalShowChat}
          messages={messages}
          onHide={() => setModalShowChat(false)}
          setNewMessage={setNewMessage}
          socket={socket}
        />
      )}
      {user && session.token && (
        <ModalAsignarComponent
          user={user}
          session={session}
          setUser={setUser}
          token={session?.token || ''}
          show={modalShowAsignar}
          onHide={() => setModalShowAsignar(false)}
          onSuccess={() => setModalShowAsignar(false)}
        />
      )}
      {loading && <LoaderComponent />}
      <HeaderShared />
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom">
            <div className="view-head">
              <div className="row">
                <div className="col-12 mb-3">
                  <a
                    href="#"
                    className="btn--back"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    <i className="icon icon--back"></i> Regresar
                  </a>
                </div>
                <div className="view-head__data col-12">
                  <div className="row">
                    <div className="view-detail col-md-8 col-12">
                      <h1 className="text-40">{`${user.username || ''}`}</h1>
                      <p className="text-20">
                        <strong>No. {`${user.userId}`}</strong>
                      </p>
                      <div className="view-detail__tags">
                        <TagsComponent tags={tagsFiltered} />
                      </div>
                    </div>
                    <aside className="view-detail__action col-md-4 col-12">
                      <div className="view-detail__action-col col-12 col-reset">
                        <button
                          type="button"
                          className="btn btn--type3"
                          onClick={handleShowModal}
                        >
                          Ver proceso
                        </button>
                        {[
                          AdminPrincipalRol.ADMIN,
                          AdminPrincipalRol.ACOMPANANTE,
                          AdminPrincipalRol.LIDER_COLECTIVA,
                        ].some((rol) =>
                          [session.principalRol, session.secondaryRol].includes(
                            rol
                          )
                        ) && (
                          <ActionsButtonComponent
                            user={user}
                            setModalShowAsignar={setModalShowAsignar}
                            updateAmiga={updateAmiga}
                            setError={setError}
                          />
                        )}
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
            <div className="view-body module-top40">
              <div className="row">
                <div className="view-body__info col-md-9 col-12">
                  <table className="table-user-data">
                    <tbody>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--message-clip"></i>{' '}
                            Registrada:
                          </p>
                        </td>
                        <td>
                          {user.createdAt
                            ? format(new Date(user.createdAt), 'dd/MMMM/yy')
                            : ''}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--user-clip"></i> Edad:
                          </p>
                        </td>
                        <td>
                          {`${user.datosSociodemograficos?.currentAge || ''}`}{' '}
                          años
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--user-clip"></i>{' '}
                            Acompañante:
                          </p>
                        </td>
                        <td>
                          {(user.assignedTo?.adminId as IAdmin)?.username ||
                            'Sin asignar'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--calendar-clip"></i>{' '}
                            Semanas:
                          </p>
                        </td>
                        <td>{`${
                          user.saludGinecologica?.pregnancyCalculator?.result ||
                          ''
                        }`}</td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--edit-clip"></i> Derivada
                            de:
                          </p>
                        </td>
                        <td>{`${user.source || ''}`}</td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--edit-clip"></i> Causal:
                          </p>
                        </td>
                        <td>
                          {user.datosCausales?.causales?.length
                            ? Array.from(
                                new Set(
                                  user.datosCausales?.causales.map(
                                    (c) => `${c.number}`
                                  )
                                )
                              ).join(', ')
                            : ''}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--edit-clip"></i> Comuna:
                          </p>
                        </td>
                        <td>{`${
                          user.datosSociodemograficos?.city?.name || ''
                        }`}</td>
                      </tr>
                    </tbody>
                  </table>
                  {[
                    AdminPrincipalRol.ADMIN,
                    AdminPrincipalRol.ACOMPANANTE,
                    AdminPrincipalRol.LIDER_COLECTIVA,
                  ].includes(session.principalRol as AdminPrincipalRol) && (
                    <>
                      {(user.datosSociodemograficos?.currentAge || 99) < 18 && (
                        <div className="col-12 mt-4">
                          <NotificationsComponent
                            message={
                              '<strong>Estás atendiendo a una menor de 18 años.</strong> Puedes derivarla a una acompañante especializada.'
                            }
                            buttonText="Derivar a Especialista"
                            buttonUrl="/"
                            status="warning"
                            user={user}
                            updateAmiga={updateAmiga}
                          />
                        </div>
                      )}
                      {notAttendedToWorkshop(user) && (
                        <div className="col-12 mt-4">
                          <NotificationsComponent
                            message={
                              '<strong>Esta mujer no asistió al taller informativo.</strong> Puedes derivarla a un nuevo taller dentro del catálogo existente.'
                            }
                            buttonText="Derivar a ayuda complementaria"
                            buttonUrl="/"
                            status="info"
                            user={user}
                            updateAmiga={updateAmiga}
                          />
                        </div>
                      )}
                      <div className="col-12 mt-4">
                        <NotificationsComponent
                          message={
                            '<strong>Esta mujer requiere de un acompañamiento para embarazo de alta complejidad.</strong> Puedes derivarla a una acompañante con esta especialidad.'
                          }
                          buttonText="Derivar a ayuda complementaria"
                          buttonUrl="/"
                          status="neutral"
                          user={user}
                          updateAmiga={updateAmiga}
                        />
                      </div>
                      {user.contextoSociocultural?.isOriginTown && (
                        <div className="col-12 mt-4">
                          <NotificationsComponent
                            message={
                              '<strong>Esta mujer requiere de un acompañamiento para mujeres que no hablan español.</strong> Puedes derivarla a una acompañante con esta especialidad.'
                            }
                            buttonText="Derivar a ayuda complementaria"
                            buttonUrl="/"
                            status="success"
                            user={user}
                            updateAmiga={updateAmiga}
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div className="col-12 mt-5">
                    <Accordion
                      defaultActiveKey="0"
                      className="accordion-regular"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Etiquetas</Accordion.Header>
                        <Accordion.Body className="pl-0 pr-0">
                          <TagsManagerComponent
                            admin={session}
                            user={user}
                            showLabel={true}
                            handleAddition={(tags) => {
                              updateAmiga({ _id: user._id || '', tags });
                            }}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Notas</Accordion.Header>
                        <Accordion.Body>
                          <NotesComponent
                            admin={session}
                            user={user}
                            handleAddNote={(note) => {
                              updateAmiga({
                                _id: user._id || '',
                                notes: [...(user.notes || []), note],
                              });
                            }}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          Información personal
                        </Accordion.Header>
                        <Accordion.Body>
                          <PersonalDataComponent user={user} readonly={true} />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          Datos Sociodemográficos
                        </Accordion.Header>
                        <Accordion.Body>
                          <DemographicDataComponent
                            user={user}
                            readonly={true}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>Salud y Cuidados</Accordion.Header>
                        <Accordion.Body>
                          <HealthDataComponent
                            user={user}
                            token={session.token || ''}
                            readonly={true}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>Contexto</Accordion.Header>
                        <Accordion.Body>
                          <InfoDataComponent user={user} />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
                {(user.step || 0) > UserSteps.MI_PROCESO &&
                  (user.assignedTo?.adminId as IAdmin)._id === session._id && (
                    <aside className="view-body__aside col-md-3 col-12">
                      <button
                        className="btn btn--chat"
                        onClick={(e) => {
                          e.preventDefault();
                          const roomId = user.assignedTo?.chatRoomId || '';
                          const token = session.token || '';
                          const userId = session._id || '';
                          fetchOldMessages(roomId, token, userId).then(() =>
                            setModalShowChat(true)
                          );
                        }}
                      >
                        Ver Chat <i className="icon icon--chat"></i>
                      </button>
                    </aside>
                  )}
                {(user.step || 0) === UserSteps.ENTREGA &&
                  (user.assignedDeliverer?.adminId as IAdmin)._id ===
                    session._id && (
                    <aside className="view-body__aside col-md-3 col-12">
                      <button
                        className="btn btn--chat"
                        onClick={(e) => {
                          e.preventDefault();
                          const roomId =
                            user.assignedDeliverer?.chatRoomId || '';
                          const token = session.token || '';
                          const userId = session._id || '';
                          fetchOldMessages(roomId, token, userId).then(() =>
                            setModalShowChat(true)
                          );
                        }}
                      >
                        Ver Chat <i className="icon icon--chat"></i>
                      </button>
                    </aside>
                  )}
              </div>
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default DetailUserScreen;
