import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { useNavigate } from 'react-router-dom';
import FooterShared from '../../../shared/Header/footer.shared';
import AccordionComplete from '../../../components/Elements/AccordionComplete/accordionComplete.component';
import HeaderShared from '../../../shared/Header/header.shared';
import { strapiDoc } from '../../../types/strapiDocs';
import { fetchStrapiDocs } from '../../../api/strapi';
import LoaderComponent from '../../../components/Loader/loader.component';

interface Item {
  id: number;
  name: string;
}

const ColectivasScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [colectivaList, setColectivaList] = useState<strapiDoc[]>([]);

  const navigate = useNavigate();

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchColectivas = async () => {
    try {
      setLoading(true);
      const query = {
        sort: ['nombre:asc'],
        filters: { pais: { $eq: 4 } }, // 4 es el id de Chile
        pagination: { pageSize: 100 },
        populate: '*',
      };
      const colectivas = await fetchStrapiDocs('/colectivas', query);
      setColectivaList(colectivas.data);
    } catch (error) {
      setError('Error al obtener las colectivas');
    } finally {
      setLoading(false);
    }
  };

  const handleOnSelect = (item: Item) => {
    navigate(`/mi-colectiva/${item.id}`);
  };

  const formatResult = (item: Item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    );
  };

  useEffect(() => {
    fetchColectivas();
  }, []);

  return (
    <>
      <HeaderShared />
      {loading && <LoaderComponent />}
      <section className="module40 container">
        <div className="body-row row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mb-4">
                <a href="/inicio-administrador" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-12 mb-5">
                <h1 className="mb-4">Colectivas</h1>
                <p>
                  Puedes buscar por: <strong>Nombre, Zona</strong>
                </p>
                <div className="form-search-complete">
                  <div className="autocomplete-search">
                    <ReactSearchAutocomplete
                      items={colectivaList.map((colectiva) => ({
                        id: colectiva.id,
                        name: colectiva.attributes.nombre,
                      }))}
                      onSelect={handleOnSelect}
                      autoFocus
                      formatResult={formatResult}
                      placeholder="Buscar colectiva"
                      className="autocomplete-search__input"
                    />
                  </div>
                  <a
                    href="https://cms.conlasamigas.org/admin/content-manager/collection-types/api::colectiva.colectiva/create"
                    className="btn btn--type1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    + Registrar una nueva colectiva
                  </a>
                </div>
              </div>
              <div className="col-12">
                {colectivaList.length &&
                  [
                    'Zona Central',
                    'Zona Costa/Valle',
                    'Zona Norte',
                    'Zona Sur',
                  ].map((zone, index) => (
                    <AccordionComplete
                      key={index}
                      zone={zone}
                      setLoading={setLoading}
                      setError={setError}
                      colectivas={colectivaList
                        .filter(
                          (colectiva) => colectiva.attributes.zona === zone
                        )
                        .map(({ id, attributes: { nombre } }) => ({
                          id,
                          name: nombre,
                        }))}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default ColectivasScreen;
