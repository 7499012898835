import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { DateRangePicker } from 'rsuite';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import TagsManagerComponent from '../../../components/Forms/tagsManager/tagsManager.component';
import AcompananteTableComponent from '../../../components/Elements/DataTable/acompananteTable.component';
import { IUser, UserSteps } from '../../../types/userInterface';
import LoaderComponent from '../../../components/Loader/loader.component';
import { fetchStrapiDocs } from '../../../api/strapi';
import { strapiDoc } from '../../../types/strapiDocs';

const AllWomenScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [colectivaList, setColectivaList] = useState<strapiDoc[]>([]);
  const [searchText, setSearchText] = useState('');
  const [userSearchTerms, setUserSearchTerms] = useState<
    Partial<IUser & { createdAtRange?: { startDate: Date; endDate: Date } }>
  >({});

  const fetchColectivas = async () => {
    try {
      setLoading(true);
      const query = {
        sort: ['nombre:asc'],
        filters: { pais: { $eq: 4 } }, // 4 es el id de Chile
        pagination: { pageSize: 100 },
        populate: '*',
      };
      const colectivas = await fetchStrapiDocs('/colectivas', query);
      setColectivaList(colectivas.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchColectivas();
  }, []);

  return (
    <>
      <HeaderShared />
      <section className="module40">
        {loading && <LoaderComponent />}
        <section className="container">
          <div className="body-row module-bottom row">
            <div className="col-12 mb-3">
              <a href="/inicio-administrador" className="btn--back">
                <i className="icon icon--back"></i> Regresar
              </a>
            </div>
            <div className="title-section module-bottom40 col-12">
              <h1 className="h2">Registro de Mujeres</h1>
            </div>
            <aside className="dashboard-aside col-md-3 col-12">
              <div className="dashboard-aside__inner">
                <h4 className="mb-4">Buscar</h4>
                <div className="form-row form--search">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <i className="icon icon--search-bar"></i>
                </div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Búsqueda avanzada </Accordion.Header>
                    <Accordion.Body>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Calendarios
                        </label>
                        <Accordion className="accordion-inner">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              Selecciona una fecha{' '}
                            </Accordion.Header>
                            <Accordion.Body>
                              <DateRangePicker
                                onChange={(value) => {
                                  const startDate = value?.[0];
                                  const endDate = value?.[1];
                                  if (startDate && endDate) {
                                    setUserSearchTerms({
                                      ...userSearchTerms,
                                      createdAtRange: {
                                        startDate: startDate,
                                        endDate: endDate,
                                      },
                                    });
                                  } else {
                                    setUserSearchTerms({
                                      ...userSearchTerms,
                                      createdAtRange: undefined,
                                    });
                                  }
                                }}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Etiquetas
                        </label>
                        <TagsManagerComponent
                          admin={{}}
                          user={userSearchTerms}
                          handleAddition={(tags) => {
                            setUserSearchTerms({
                              ...userSearchTerms,
                              tags: tags,
                            });
                          }}
                        />
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Estado
                        </label>
                        <select
                          className="form-select form--small"
                          aria-label="Estado"
                          defaultValue={'default'}
                          value={`${userSearchTerms.step || 'default'}`}
                          name="estado"
                          onChange={(e) => {
                            setUserSearchTerms({
                              ...userSearchTerms,
                              step: Number(e.target.value),
                            });
                          }}
                        >
                          <option value="default">Selecciona un estado</option>
                          <option value={UserSteps.INCIDENCIAS}>
                            Registrada
                          </option>
                          <option value={UserSteps.MI_PROCESO}>
                            En espera
                          </option>
                          <option value={UserSteps.TALLER}>En taller</option>
                          <option value={UserSteps.ENTREGA}>En entrega</option>
                          <option value={UserSteps.ENTREGA_SUCCESS}>
                            Entrega realizada
                          </option>
                          <option value={UserSteps.PENDING_EVALUATION}>
                            Evaluación pendiente
                          </option>
                          <option value={UserSteps.PROCESS_COMPLETE}>
                            Proceso completado
                          </option>
                        </select>
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Colectiva
                        </label>
                        <select
                          className="form-select form--small"
                          aria-label="Colectiva"
                          defaultValue={'default'}
                          name="colectiva"
                          onChange={(e) => {
                            setUserSearchTerms({
                              ...userSearchTerms,
                              colectiva: {
                                value: e.target.value,
                              },
                            });
                          }}
                        >
                          <option value="default">
                            Selecciona una colectiva
                          </option>
                          {colectivaList.map((colectiva) => (
                            <option
                              key={colectiva.id}
                              value={colectiva.attributes.nombre}
                            >
                              {colectiva.attributes.nombre}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </aside>
            <div className="dashboard-info col-md-9 col-12">
              <AcompananteTableComponent
                searchText={searchText}
                userSearchTerms={userSearchTerms}
              />
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default AllWomenScreen;
