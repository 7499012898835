import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

interface ITimePickerProps {
  onChange: (value: Date | null) => void;
  label: string;
  value?: Date | null;
  disabled?: boolean;
}

const BasicTimePicker: React.FC<ITimePickerProps> = ({
  onChange,
  label,
  value,
  disabled = false,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DemoContainer components={['TimePicker']}>
        <TimePicker
          label={label}
          onChange={onChange}
          className={`form-control inputRequired`}
          value={value}
          disabled={disabled}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default BasicTimePicker;
