import React, { useState } from 'react';
import HeaderShared from '../../shared/Header/header.shared';
import FooterShared from '../../shared/Header/footer.shared';

const EditAdminSuccessScreen: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userLogged, setUserLogged] = useState(false);
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom40 row">
              <div className="col-lg-10 col-12 mx-auto text-center">
                <div className="d-flex justify-content-center">
                  <figure className="image-490">
                    <img
                      src="/assets/images/illustrations/cat.png"
                      alt="Confirmación"
                    />
                  </figure>
                </div>
                <h1 className="text-40 mb-3">
                  ¡ Se actualizaron los datos con éxito !
                </h1>
                <p>
                  La información de <strong>RAFAELA</strong> fue acutalizada con
                  éxito y todas podrán ver los cambios en las su ficha
                </p>
              </div>
            </div>
            <div className="form-row  form--last">
              <a
                href="/detalle-acompanante"
                className="btn btn--type1 btn--270"
              >
                Regresar a perfil
              </a>
            </div>
          </div>
        </div>
      </section>

      <FooterShared />
    </>
  );
};

export default EditAdminSuccessScreen;
