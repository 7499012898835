import LoginScreen from '../pages/Auth/Login/login.screen';
import NotFoundScreen from '../pages/NotFound/notFound.screen';
import { getSession } from './helpers';

interface PrivateRouteProps {
  principalRol: string[];
  children: JSX.Element;
}

const PrivateRoute = ({
  children,
  principalRol,
}: PrivateRouteProps): JSX.Element | null => {
  const session = getSession();
  const isLoggedIn = session ? true : false;

  const validRol = (rol: string[]): boolean => {
    if (!session) {
      return false;
    }
    const { principalRol: sessionRol, secondaryRol } = session;
    if ([sessionRol, secondaryRol].some((adminRol) => rol.includes(adminRol))) {
      return true;
    }
    if (rol.includes('All')) {
      return true;
    }
    return false;
  };

  return isLoggedIn
    ? validRol(principalRol)
      ? children
      : NotFoundScreen()
    : LoginScreen();
};

export default PrivateRoute;
