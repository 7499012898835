import React from 'react';
import ColectivaItemComponent from '../Buttons/colectivaItem.component';
import { IAdmin } from '../../../types/adminInterface';

interface ColectivasListComponentProps {
  colectivas: IAdmin[];
}

const ColectivasListComponent: React.FC<ColectivasListComponentProps> = ({
  colectivas,
}) => {
  return (
    <div className="colectiva-list-data">
      <ul>
        {colectivas.map((colectiva, index) => (
          <li key={index}>
            <ColectivaItemComponent
              colectiva={colectiva.colectiva?.name || ''}
              organizer={colectiva.username}
              amount={0}
              quantity={0}
              url={`/mi-colectiva/${colectiva._id}`}
            />
          </li>
        ))}
        <li></li>
      </ul>
    </div>
  );
};

export default ColectivasListComponent;
