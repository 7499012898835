export const TAGS = [
  { id: 'origen-ola', text: 'Origen: OLA' },
  { id: 'perfil-linea', text: 'Perfil: Línea' },
  { id: 'origen-ive', text: 'Origen: IVE' },
  { id: 'repeticion', text: 'Repetición' },
  { id: 'acude-a-hospital', text: 'Acude a hospital' },
  { id: 'vitaminas-dosis-extra', text: 'Vitaminas dosis extra' },
  { id: 'transferencia', text: 'Transferencia' },
  { id: 'inasistente a taller', text: 'Inasistente a taller' },
  { id: 'no-da-aporte', text: 'No da aporte' },
  { id: 'vcm', text: 'VCM' },
  { id: 'causales-c1', text: 'C1' },
  { id: 'causales-c2', text: 'C2' },
  { id: 'causales-c3', text: 'C3' },
  { id: 'continua-embarazo', text: 'Continua embarazo' },
  { id: 'estafa', text: 'Estafa' },
];

export const TAGS_DATA = [
  { id: 'tag-1', className: 'badge--ola', text: 'DERIVADA DE OLA' },
  {
    id: 'tag-2',
    className: 'badge--medica',
    text: 'DERIVADA DE ESPECIALIDAD MÉDICA',
  },
  {
    id: 'tag-3',
    className: 'badge--colectiva',
    text: 'DERIVADA DE OTRA COLECTIVA',
  },
  {
    id: 'tag-4',
    className: 'badge--warning badge--icon',
    text: 'ES MENOR DE EDAD',
    icon: 'report_problem',
  },
  {
    id: 'tag-5',
    className: 'badge--danger badge--icon',
    text: 'Situación de violencia',
    icon: 'report_problem',
  },
  {
    id: 'tag-6',
    className: 'badge--psicologica badge--icon',
    text: 'Contención psicológica',
    icon: 'report_problem',
  },
  {
    id: 'tag-7',
    className: 'badge--complejidad badge--icon',
    text: 'Alta complejidad',
    icon: 'report_problem',
  },
  {
    id: 'tag-8',
    className: 'badge--discapacidad badge--icon',
    text: 'Discapacidad',
    icon: 'report_problem',
  },
  {
    id: 'tag-9',
    className: 'badge--no-spanish badge--icon',
    text: 'No habla español',
    icon: 'report_problem',
  },
  {
    id: 'tag-10',
    className: 'badge--active badge--icon',
    text: 'Cuenta activa',
    icon: 'verified',
  },
  {
    id: 'tag-11',
    className: 'badge--inactive badge--icon',
    text: 'CUENTA DESACTIVADA TEMPORALMENTE',
    icon: 'cancel',
  },
  {
    id: 'tag-12',
    className: 'badge--inactive badge--icon',
    text: 'CUENTA ELIMINADA',
    icon: 'cancel',
  },
];
