import React, { useState, useEffect } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import ModalDeleteTagComponent from '../../Elements/Modal/ModalDeleteTag/modalDeleteTag.component';
import { IUser } from '../../../types/userInterface';
import { IAdmin, IAdminTag } from '../../../types/adminInterface';

interface TagsManagerComponentProps {
  admin: Partial<IAdmin>;
  user: Partial<IUser>;
  handleAddition?: (tags: IAdminTag[]) => void;
  showLabel?: boolean;
  labelText?: string;
}

const TagsManagerComponent: React.FC<TagsManagerComponentProps> = ({
  admin,
  user,
  handleAddition: onAddition,
  showLabel = false,
  labelText = 'Agregar etiqueta',
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [tagToDeleteIndex, setTagToDeleteIndex] = useState<number | null>(null);
  const [tags, setTags] = useState<IAdminTag[]>([]);
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleDelete = (i: number) => {
    // Mostrar el modal para confirmar la eliminación
    setTagToDeleteIndex(i);
    setModalShow(true);
  };

  const handleTagClick = (index: number) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const localHandleAddition = (tag: {
    id: string;
    text: string;
    className: string;
  }) => {
    const createdAt = new Date().toISOString();
    const newTags = [
      ...tags,
      { tag: tag.text, adminId: admin._id || '', createdAt },
    ];
    setTags(newTags);
    if (onAddition) {
      onAddition(newTags);
    }
  };

  useEffect(() => {
    const tags = user.tags || [];
    setTags(tags);
  }, [user]); // Se ejecuta solo una vez al montar el componente

  // Función para eliminar el tag después de la confirmación
  const handleConfirmDelete = () => {
    if (tagToDeleteIndex !== null) {
      setTags(tags.filter((_, index) => index !== tagToDeleteIndex));
      if (onAddition) {
        onAddition(tags.filter((_, index) => index !== tagToDeleteIndex));
      }
    }
    setModalShow(false);
  };

  return (
    <>
      <ModalDeleteTagComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleConfirmDelete}
      />
      {showLabel && (
        <label className="form-label label--title text-16 label--icon">
          <span className="material-icons-outlined">local_offer</span>
          {labelText}
        </label>
      )}
      {tags.length === 0 && (
        <p className="text-center mb-2">No hay etiquetas asignadas</p>
      )}
      <ReactTags
        tags={tags.map((tag, i) => ({
          id: `${i}`,
          text: tag.tag,
          className: 'tag',
        }))}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={(tag) =>
          localHandleAddition({
            id: tag.id,
            text: tag.text,
            className: 'tag',
          })
        }
        handleTagClick={handleTagClick}
        inputFieldPosition="bottom"
        autocomplete
        placeholder="Escribe una palabra clave y selecciona"
      />
    </>
  );
};

export default TagsManagerComponent;
