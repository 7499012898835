import React from 'react';

interface ColectivaData {
  id?: number;
  name: string;
  women?: number;
  lider?: string;
  acompanantes?: string;
  talleristas?: string;
  entregadoras?: string;
  aporte?: number;
  url?: string;
}

interface ColectivasTableProps {
  colectiva: ColectivaData;
}

const ColectivasTable: React.FC<ColectivasTableProps> = ({ colectiva }) => {
  return (
    <table className="accordion-table">
      <tr>
        <td>
          <span>
            <strong>Mujeres</strong>
          </span>
        </td>
        <td className="text-right">
          <span className="text-14">{colectiva.women || 0}</span>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            <strong>Líder de Colectiva</strong>
          </span>
        </td>
        <td className="text-right">
          <span className="text-14">{colectiva.lider || 'Sin asignar'}</span>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            <strong>Acompañantes</strong>
          </span>
        </td>
        <td className="text-right">
          <span className="text-14">
            {colectiva.acompanantes || 'Sin asignar'}
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            <strong>Talleristas</strong>
          </span>
        </td>
        <td className="text-right">
          <span className="text-14">
            {colectiva.talleristas || 'Sin asignar'}
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            <strong>Entregadoras</strong>
          </span>
        </td>
        <td className="text-right">
          <span className="text-14">
            {colectiva.entregadoras || 'Sin asignar'}
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            <strong>Aporte Total</strong>
          </span>
        </td>
        <td className="text-right">
          <span className="text-14">
            $
            {(colectiva.aporte || 0).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
            CLP
          </span>
        </td>
      </tr>
      <tr>
        <td></td>
        <td className="text-right">
          <a
            href={`/mi-colectiva/${colectiva.id}`}
            className="text-decoration-none"
          >
            Ver detalle
          </a>
        </td>
      </tr>
    </table>
  );
};

export default ColectivasTable;
