import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import NotificationsComponent from '../../../../components/Elements/Notifications/notifications.component';
import TallerTableComponent from '../../../../components/Elements/DataTable/tallerTable.component';
import { IWorkshop } from '../../../../types/workshopInterface';
import LoaderComponent from '../../../../components/Loader/loader.component';
import { getWorkshop } from '../../../../api/workshopApi';
import { getSession } from '../../../../utils/helpers';
import { AdminPrincipalRol, IAdmin } from '../../../../types/adminInterface';
import { IUser } from '../../../../types/userInterface';
import { searchAdmins } from '../../../../api/adminApi';

const TalleresAsistentesScreen: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [workshop, setWorkshop] = useState<Partial<IWorkshop>>({});
  const [adminId, setAdminId] = useState<Partial<IAdmin>>({});
  const [attenders, setAttenders] = useState<IUser[]>([]);
  const [assignedToList, setAssignedToList] = useState<IAdmin[]>([]);
  const [entregadoras, setEntregadoras] = useState<IAdmin[]>([]);

  const { id } = useParams<{ id: string }>();

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchWorkshop = async () => {
    try {
      const session = getSession();
      const response = await getWorkshop(id || '', session?.token || '');
      if (response) {
        setWorkshop(response);
        setAdminId(response.adminId as IAdmin);
        setAttenders([
          ...response.assignedUsers.map((user) => ({
            apuntes: user.apuntes || '',
            workshopStatus: user.status,
            ...(user.userId as IUser),
          })),
        ]);
        const adminIds = response.assignedUsers.map(
          (user) => ((user.userId as IUser).assignedTo?.adminId as string) || ''
        );
        const assignedDelivererIds = response.assignedUsers.map(
          (user) =>
            ((user.userId as IUser).assignedDeliverer?.adminId as string) || ''
        );
        const uniqueAdminIds = Array.from(
          new Set([...adminIds, ...assignedDelivererIds])
        );
        const adminsResponse = await searchAdmins(
          1,
          100,
          { ids: uniqueAdminIds },
          session?.token || ''
        );
        if (adminsResponse && adminsResponse.docs) {
          setAssignedToList(adminsResponse.docs);
        }
      }
    } catch (error) {
      setError('Error al cargar el taller');
    } finally {
      setLoading(false);
    }
  };

  const fetchEntregadoras = async () => {
    try {
      const session = getSession();
      const response = await searchAdmins(
        1,
        100,
        {
          principalRol: AdminPrincipalRol.ENTREGADORA,
          colectiva: {
            name: session?.colectiva?.name || '',
          },
        },
        session?.token || ''
      );
      if (response && response.docs) {
        setEntregadoras(response.docs);
      }
    } catch (error) {
      setError('Error al cargar las entregadoras');
    }
  };

  useEffect(() => {
    fetchWorkshop();
    fetchEntregadoras();
  }, []);

  return (
    <>
      <HeaderShared />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mb-4">
                <a href="/talleres" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-12 mb-5">
                <div className="top-title">
                  <h1 className="mb-3">
                    Taller {`"${workshop.name || ''}"`} del{' '}
                    {workshop.workshopTextDate}
                  </h1>
                  <p className="text-purple500">
                    <strong>
                      Cupo:{' '}
                      {`${(workshop.assignedUsers || []).length}/${
                        workshop.availablePlaces
                      }`}
                    </strong>
                  </p>
                </div>
                <p>
                  <strong>Impartido por:</strong> {adminId.username || ''}
                </p>
                <p>
                  <strong>Fecha:</strong> {workshop.workshopTextDate}
                </p>
                <p>
                  <strong>Horario:</strong> {workshop.workshopSchedule}
                </p>
                <div className="col-12 mb-3">
                  <NotificationsComponent
                    message={
                      'Recuerda que por cuidado y privacidad de la información, <strong>una vez concluido el taller se eliminará</strong> de tu sesión en 30 días posteriores'
                    }
                    user={{}}
                    status="warning"
                  />
                </div>
                <p className="mb-2">Buscar</p>
                <div className="form-row form--search">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Buscar"
                  />
                  <i className="icon icon--search-bar"></i>
                </div>
              </div>
              <div className="col-12 mb-5">
                <h4 className="mb-4">Mujeres en el taller</h4>
                <TallerTableComponent
                  attenders={attenders}
                  entregadoras={entregadoras}
                  assignedToList={assignedToList}
                  editAsistentes={false}
                  terminado={true}
                />
              </div>
              <div className="d-flex justify-content-center col-12">
                <Link
                  to={`/talleres-checklist/${workshop._id}`}
                  className="btn btn--type1"
                >
                  Editar asistentes
                </Link>
                <Link to={`/talleres`} className="btn btn--type2">
                  Regresar a los talleres
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default TalleresAsistentesScreen;
