import React from 'react';
import { Link } from 'react-router-dom';

interface AdminCardProps {
  name: string; // Nombre del usuario
  role: string; // Rol del usuario
  subRole?: string; // Subrol del usuario
  responseTime?: string; // Tiempo de respuesta del usuario
  url?: string;
  title?: string;
  colectiva?: string;
}

const AdminCard: React.FC<AdminCardProps> = ({
  name,
  role,
  subRole,
  responseTime,
  url,
  title,
  colectiva,
}) => {
  return (
    <div className="user-card user--hover">
      {url && <Link to={url} className="user-card__url"></Link>}
      <div className="chatbox-action__user">
        <figure>
          <img src="/assets/images/icons/user-pic-2.svg" alt={name} />
        </figure>
        <article>
          {title == 'h4' ? <h4>{name}</h4> : <h2>{name}</h2>}
          <p className="text-20">{role}</p>
          {subRole && <p className="text-10 text-purple800 text-500">{subRole}</p>}
          {colectiva && <p className="text-10 text-gray600">{colectiva}</p>}
        </article>
      </div>
      {responseTime && (
        <div className="user-card__time">
          <p className="mb-0 text-purple800">
            <strong>Mi tiempo de respuesta</strong>
          </p>
          <p className="mb-0 text-icon">
            <i className="icon icon--clock"></i> {responseTime}
          </p>
        </div>
      )}
    </div>
  );
};

export default AdminCard;
