import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AdminPrincipalRol, IAdmin } from '../../../types/adminInterface';
import { fetchStrapiDocs } from '../../../api/strapi';
import { strapiDoc } from '../../../types/strapiDocs';
import { useNavigate } from 'react-router-dom';
import LoaderComponent from '../../Loader/loader.component';
import { getSession } from '../../../utils/helpers';

interface AcompananteDataProps {
  admin?: Partial<IAdmin>;
  saveAdmin?: (admin: Partial<IAdmin>, adminId: string) => void;
}

const AcompananteDataComponent: React.FC<AcompananteDataProps> = ({
  admin: prevAdmin,
  saveAdmin,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const [session, setSession] = useState<Partial<IAdmin>>({});
  const [countryList, setCountryList] = useState<strapiDoc[]>([]);
  const [stateList, setStateList] = useState<strapiDoc[]>([]);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const calculateAge = (birthday: string) => {
    const birthDate = new Date(birthday);
    const difference = Date.now() - birthDate.getTime();
    const ageDate = new Date(difference);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const fetchStates = async (countryId: number) => {
    // Fetch states
    const query = {
      sort: ['nombre:asc'],
      filters: {
        pais: { $eq: countryId },
      },
      pagination: { pageSize: 100 },
      populate: '*',
    };
    const states = await fetchStrapiDocs('/estados', query);
    setStateList(states.data);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch countries
        const query = { sort: ['nombre:asc'] };
        const countries = await fetchStrapiDocs('/paises', query);
        const data = countries.data;
        data.push({ id: 0, attributes: { nombre: 'Otro' } });
        setCountryList(countries.data);

        const session = getSession();
        if (!session || !session.token) {
          navigate('/login');
          return;
        }
        setSession(session);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        if (prevAdmin && prevAdmin.username) {
          setAdmin(prevAdmin);
          if (prevAdmin.birthday) {
            setAdmin({
              ...prevAdmin,
              age: calculateAge(prevAdmin.birthday),
            });
          }
          if (prevAdmin.country?.id) {
            fetchStates(prevAdmin.country.id || 0);
          }
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (prevAdmin && prevAdmin._id) {
      setAdmin({ ...prevAdmin, age: calculateAge(prevAdmin.birthday || '') });
    }
  }, [prevAdmin?._id]);

  return (
    <div className="form-block">
      {loading && <LoaderComponent />}
      <form>
        <div className="form-row">
          <label htmlFor="name" className="form-label label--icon">
            Nombre o seudónimo <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Nombre del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Escribe el nombre"
            value={admin?.username || ''}
            onChange={(e) => setAdmin({ ...admin, username: e.target.value })}
          />
        </div>
        <div className="form-row">
          <label htmlFor="birthday" className="form-label label--icon">
            Correo electrónico <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Correo electrónico del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Escribe el correo electrónico"
            value={admin?.email || ''}
            readOnly={true}
            onChange={(e) => setAdmin({ ...admin, email: e.target.value })}
          />
        </div>
        {(session.principalRol === AdminPrincipalRol.ADMIN ||
          session._id === admin._id) && (
          <div className="form-row">
            <label
              htmlFor="password"
              className={`form-label label--icon ${
                !admin.password && (admin.password || '').length < 6
                  ? 'error'
                  : ''
              }`}
            >
              Contraseña <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Contraseña del usuario.</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <div className="form-password">
              <button
                type="button"
                className="toggle-password"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                <span className="material-icons-outlined icon">
                  {isPasswordVisible ? 'visibility_off' : 'visibility'}
                </span>
              </button>
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                className="form-control"
                id="password"
                placeholder="Escribe la contraseña"
                onChange={(e) =>
                  setAdmin({ ...admin, password: e.target.value })
                }
              />
            </div>
          </div>
        )}
        <div className="form-row">
          <label htmlFor="nacionalidad" className="form-label label--icon">
            Nacionalidad <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Nacionalidad del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <select
            id="nacionalidad"
            className="form-select"
            aria-label="Tu nacionalidad"
            value={admin?.country?.id || ''}
            onChange={(e) => {
              const selectedCountryId = parseInt(e.target.value);
              setAdmin({ ...admin, country: { id: selectedCountryId } });
              fetchStates(selectedCountryId);
            }}
          >
            <option value="default">Elige una de las opciones</option>
            {countryList.map((country) => (
              <option key={country.id} value={country.id}>
                {country.attributes.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="form-row">
          <label htmlFor="region" className="form-label label--icon">
            Región <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Región del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <select
            id="region"
            className="form-select"
            aria-label="Tu región"
            value={admin?.state?.id || ''}
            onChange={(e) =>
              setAdmin({ ...admin, state: { id: parseInt(e.target.value) } })
            }
          >
            <option value="default" disabled>
              Elige una de las opciones
            </option>
            {stateList.map((state, index) => (
              <option key={index} value={state.id}>
                {state.attributes.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="form-row form--last">
          <a
            className="btn btn--type2"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            Regresar
          </a>
        </div>
        {saveAdmin && admin._id && (
          <div className="form-row form--last">
            <button
              className="btn btn--type1 btn--100"
              onClick={(event) => {
                event.preventDefault();
                saveAdmin(admin, admin._id || '');
              }}
            >
              Guardar cambios
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default AcompananteDataComponent;
