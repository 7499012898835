import React, { useEffect, useState } from 'react';
import HeaderShared from '../../shared/Header/header.shared';
import FooterShared from '../../shared/Header/footer.shared';
import { fetchStrapiSingle } from '../../api/strapi';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const TermsScreen: React.FC = () => {
  const [privacyContent, setPrivacyContent] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPrivacyContent = async () => {
      try {
        setLoading(true);
        const response = await fetchStrapiSingle('/terminos-y-condicion', {});
        setPrivacyContent(response.data.attributes.Description || ''); // Asegúrate de que el contenido esté en `content`
      } catch (err) {
        console.error(err);
        setError('Hubo un problema al cargar los términos.');
      } finally {
        setLoading(false);
      }
    };

    fetchPrivacyContent();
  }, []);

  return (
    <>
      <HeaderShared />
      <section className="module">
        <section className="container">
          <div className="body-row module-bottom">
            <div className="row">
              <div className="col-md-8 col-12 mt-4 mx-auto">
                <article>
                  {loading && <p>Cargando...</p>}
                  {error && <p>{error}</p>}
                  {privacyContent && (
                    <>
                      <h1 className="h3 text-regular mb-4">Términos y condiciones</h1>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {privacyContent}
                      </ReactMarkdown>
                    </>
                  )}
                </article>
              </div>
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default TermsScreen;