import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { io, Socket } from 'socket.io-client';
import Swal from 'sweetalert2';
import { MessageBoxType } from 'react-chat-elements';
import { DateRangePicker } from 'rsuite';

import { useParams } from 'react-router-dom';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import TagsManagerComponent from '../../../../components/Forms/tagsManager/tagsManager.component';
import AdminCardProps from '../../../../components/Elements/AdminCard/adminCard.component';
import AcompananteTableComponent from '../../../../components/Elements/DataTable/acompananteTable.component';
import LoaderComponent from '../../../../components/Loader/loader.component';
import { getSession } from '../../../../utils/helpers';
import { searchAdmins } from '../../../../api/adminApi';
import {
  fetchChatMessages,
  getChatRoom,
  newChatRoom,
} from '../../../../api/chatApi';
import { AdminPrincipalRol, IAdmin } from '../../../../types/adminInterface';
import ChatModalComponent from '../../../../components/Chat/chatModal.component';
import { IUser, UserSteps } from '../../../../types/userInterface';
import { searchWorkshops } from '../../../../api/workshopApi';
import { IWorkshop } from '../../../../types/workshopInterface';
import { fetchStrapiSingle } from '../../../../api/strapi';

const ColectivaScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState<Partial<IAdmin>>({});
  const [toAdmin, setToAdmin] = useState<Partial<IAdmin>>({});
  const [searchText, setSearchText] = useState('');
  const [userSearchTerms, setUserSearchTerms] = useState<
    Partial<IUser & { createdAtRange?: { startDate: Date; endDate: Date } }>
  >({});
  const [colectivaAdmins, setColectivaAdmins] = useState<IAdmin[]>([]);
  const [generalAdmins, setGeneralAdmins] = useState<IAdmin[]>([]);
  const [socket, setSocket] = useState<Socket>();
  const [messages, setMessages] = useState<MessageBoxType[]>([]);
  const [modalShowChat, setModalShowChat] = useState(false);
  const [currentChatRoomId, setCurrentChatRoomId] = useState<string>('');
  const [workshops, setWorkshops] = useState<IWorkshop[]>([]);
  const [selectedColectiva, setSelectedColectiva] = useState<string>('');

  const { id: colectivaId } = useParams<{ id: string }>();

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const getSocket = async () => {
    const socket = io('https://chat.conlasamigas.org/');
    socket.on('connect', () => {
      console.log('Connected to server');
    });
    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });
    setSocket(socket);
  };

  const fetchPathColectiva = async () => {
    try {
      const response = await fetchStrapiSingle(
        `/colectivas/${colectivaId}`,
        {}
      );
      if (response.data) {
        return response.data.attributes.nombre;
      }
    } catch (error) {
      setError('Error al obtener la colectiva');
    }
    return '';
  };

  const fetchOldMessages = async (
    roomId: string,
    token: string,
    userId: string
  ) => {
    try {
      setLoading(true);
      const response = await fetchChatMessages(roomId, token);

      const messages = response.docs.reverse().map((doc) => {
        const message: MessageBoxType = {
          id: doc._id || '',
          position: doc.sender === userId ? 'right' : 'left',
          type: 'text',
          text: doc.message,
          date: new Date(doc.createdAt || ''),
          avatar:
            doc.sender === userId
              ? '/assets/images/icons/user-small.svg'
              : '/assets/images/icons/user-pic-2.svg',
          title:
            doc.sender === userId ? session?.username || 'Tu' : doc.senderName,
          focus: false,
          forwarded: false,
          replyButton: false,
          removeButton: false,
          titleColor: '#000000',
          status: doc.sender === userId ? 'sent' : 'received',
          notch: true,
          retracted: false,
        };
        return message;
      });

      setMessages([...messages]);
      return messages;
    } catch (error) {
      console.error('Error fetching old messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const setNewMessage = (msg: MessageBoxType) => {
    setMessages((prevMessages) => [...prevMessages, msg]);
    // scroll to the bottom of the chat element with class .rce-mlist
    setTimeout(() => {
      const chatContainer = document.querySelector('.rce-mlist');
      if (chatContainer) {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight + 200,
          behavior: 'smooth',
        });
      }
    }, 300);
  };

  const fetchColectivaAdmins = async (selectedColectiva: string) => {
    try {
      setLoading(true);
      const session = getSession();
      if (!session) {
        return;
      }
      setSession(session);
      const colectiva = selectedColectiva
        ? selectedColectiva
        : session.colectiva?.name || '';
      setSelectedColectiva(colectiva);
      const response = await searchAdmins(
        0,
        100,
        { colectiva: { name: colectiva } },
        session?.token || ''
      );

      const allAdmins = await searchAdmins(
        0,
        100,
        { principalRol: AdminPrincipalRol.ADMIN },
        session?.token || ''
      );

      setGeneralAdmins(allAdmins.docs);
      setColectivaAdmins(response.docs);
      setUserSearchTerms({
        colectiva: { value: colectiva },
      });
    } catch (error) {
      setError(
        'Ocurrió un error al obtener los administradores de la colectiva'
      );
    } finally {
      setLoading(false);
    }
  };

  const findAndFetchChat = async (adminId: string) => {
    try {
      setLoading(true);
      const foundedChatRoom = await getChatRoom(
        adminId,
        session._id || '',
        session.token || ''
      );
      if (foundedChatRoom && foundedChatRoom.roomId) {
        await fetchOldMessages(
          foundedChatRoom.roomId,
          session.token || '',
          session._id || ''
        );
        setModalShowChat(true);
        setCurrentChatRoomId(foundedChatRoom.roomId);
      } else {
        const chatRoom = await newChatRoom(
          { userIdA: adminId, userIdB: session._id || '' },
          session.token || ''
        );
        if (chatRoom && chatRoom.roomId) {
          setMessages([]);
          setCurrentChatRoomId(chatRoom.roomId);
          setModalShowChat(true);
        }
      }
    } catch (error) {
      setError('Ocurrió un error al obtener el chat');
    } finally {
      setLoading(false);
    }
  };

  const fetchWorkshops = async (selectedColectiva: string) => {
    try {
      const session = getSession();
      if (session) {
        const colectiva = selectedColectiva
          ? selectedColectiva
          : session.colectiva?.name || '';
        const response = await searchWorkshops(
          session?.token || '',
          {
            page: 1,
            limit: 5,
          },
          {
            colectiva,
          }
        );
        setWorkshops(response.docs);
      }
    } catch (error) {
      setError('Ocurrió un error al obtener los talleres');
    }
  };

  useEffect(() => {
    getSocket();
    if (colectivaId) {
      fetchPathColectiva().then((selectedColectiva) => {
        fetchColectivaAdmins(selectedColectiva);
        fetchWorkshops(selectedColectiva);
      });
    } else {
      fetchColectivaAdmins('');
      fetchWorkshops('');
    }
  }, [colectivaId]);

  return (
    <>
      <HeaderShared />
      {loading && <LoaderComponent />}
      {modalShowChat && socket && (
        <ChatModalComponent
          admin={session}
          toAdmin={toAdmin}
          roomId={currentChatRoomId}
          token={session?.token || ''}
          show={modalShowChat}
          messages={messages}
          onHide={() => setModalShowChat(false)}
          setNewMessage={setNewMessage}
          socket={socket}
        />
      )}
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom row">
            <aside className="dashboard-aside col-md-3 col-12">
              <div className="title-section col-12">
                <h1 className="h2">Inicio</h1>
                <p className="h5">Colectiva {selectedColectiva}</p>
                <a href="#" className="btn btn--with-number">
                  Aporte total $5,000 CLP{' '}
                  <i className="icon icon--arrow-right-large"></i>
                </a>
              </div>
              <div className="dashboard-aside__inner">
                <h4 className="mb-4">Buscar</h4>
                <div className="form-row form--search">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <i className="icon icon--search-bar"></i>
                </div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Búsqueda avanzada </Accordion.Header>
                    <Accordion.Body>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Calendarios
                        </label>
                        <Accordion className="accordion-inner">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              Selecciona una fecha{' '}
                            </Accordion.Header>
                            <Accordion.Body>
                              <DateRangePicker
                                onChange={(value) => {
                                  console.log('DateRangePicker', value);
                                  const startDate = value?.[0];
                                  const endDate = value?.[1];
                                  if (startDate && endDate) {
                                    setUserSearchTerms({
                                      ...userSearchTerms,
                                      createdAtRange: {
                                        startDate: startDate,
                                        endDate: endDate,
                                      },
                                    });
                                  } else {
                                    setUserSearchTerms({
                                      ...userSearchTerms,
                                      createdAtRange: undefined,
                                    });
                                  }
                                }}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Etiquetas
                        </label>
                        <TagsManagerComponent
                          admin={{}}
                          user={userSearchTerms}
                          handleAddition={(tags) => {
                            setUserSearchTerms({
                              ...userSearchTerms,
                              tags: tags,
                            });
                          }}
                        />
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Estado
                        </label>
                        <select
                          className="form-select form--small"
                          aria-label="Estado"
                          defaultValue={'default'}
                          value={`${userSearchTerms.step || 'default'}`}
                          name="estado"
                          onChange={(e) => {
                            setUserSearchTerms({
                              ...userSearchTerms,
                              step: Number(e.target.value),
                            });
                          }}
                        >
                          <option value="default">Selecciona un estado</option>
                          <option value={UserSteps.INCIDENCIAS}>
                            Registrada
                          </option>
                          <option value={UserSteps.MI_PROCESO}>
                            En espera
                          </option>
                          <option value={UserSteps.TALLER}>En taller</option>
                          <option value={UserSteps.ENTREGA}>En entrega</option>
                          <option value={UserSteps.ENTREGA_SUCCESS}>
                            Entrega realizada
                          </option>
                          <option value={UserSteps.PENDING_EVALUATION}>
                            Evaluación pendiente
                          </option>
                          <option value={UserSteps.PROCESS_COMPLETE}>
                            Proceso completado
                          </option>
                        </select>
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Taller
                        </label>
                        <select
                          className="form-select form--small"
                          aria-label="Taller"
                          defaultValue={''}
                          name="taller"
                          value={
                            (userSearchTerms?.workshopSelected
                              ?.workshopId as string) || ''
                          }
                          onChange={(e) => {
                            setUserSearchTerms({
                              ...userSearchTerms,
                              workshopSelected: {
                                workshopId: e.target.value,
                              },
                            });
                          }}
                        >
                          <option value="">Selecciona un taller</option>
                          {workshops.map((workshop) => (
                            <option key={workshop._id} value={workshop._id}>
                              {workshop.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Chat interno</Accordion.Header>
                    <Accordion.Body>
                      <ul className="user-pic-selection">
                        {colectivaAdmins
                          .filter(
                            (admin) =>
                              admin._id !== session._id &&
                              admin.principalRol !== AdminPrincipalRol.ADMIN
                          )
                          .map((colectivaAdmin, index) => (
                            <li key={index}>
                              <button
                                type="button"
                                onClick={() => {
                                  findAndFetchChat(colectivaAdmin._id).then(
                                    () => setToAdmin({ ...colectivaAdmin })
                                  );
                                }}
                              >
                                <abbr
                                  title={`${colectivaAdmin.principalRol} - ${colectivaAdmin.username}`}
                                >
                                  <figure>
                                    <img
                                      src="/assets/images/icons/user-admin.svg"
                                      alt="User image"
                                    />
                                  </figure>
                                </abbr>
                              </button>
                            </li>
                          ))}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Chat administradoras</Accordion.Header>
                    <Accordion.Body>
                      <ul className="user-pic-selection">
                        {generalAdmins
                          .filter((admin) => admin._id !== session._id)
                          .map((colectivaAdmin, index) => (
                            <li key={index}>
                              <button
                                type="button"
                                onClick={() => {
                                  findAndFetchChat(colectivaAdmin._id).then(
                                    () => setToAdmin({ ...colectivaAdmin })
                                  );
                                }}
                              >
                                <abbr title={`${colectivaAdmin.username}`}>
                                  <figure>
                                    <img
                                      src="/assets/images/icons/user-admin.svg"
                                      alt="User image"
                                    />
                                  </figure>
                                </abbr>
                              </button>
                            </li>
                          ))}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {[
                  AdminPrincipalRol.ADMIN,
                  AdminPrincipalRol.LIDER_COLECTIVA,
                ].includes(session.principalRol as AdminPrincipalRol) && (
                  <div className="col-12 col-reset pt-3 pb-4">
                    <a href="/nueva-amiga" className="btn btn--type1 btn--100">
                      Registrar nueva amiga
                    </a>
                  </div>
                )}
                {/* <div className="col-12 col-reset d-flex justify-content-center">
                  <button type="button" className="btn--danger">
                    Eliminar Colectiva
                  </button>
                </div> */}
              </div>
            </aside>
            <div className="dashboard-info col-md-9 col-12">
              <h4 className="mb-4">Mujeres en tu colectiva</h4>
              {session._id && userSearchTerms.colectiva?.value && (
                <AcompananteTableComponent
                  searchText={searchText}
                  userSearchTerms={userSearchTerms}
                />
              )}
              {[
                AdminPrincipalRol.ADMIN,
                AdminPrincipalRol.LIDER_COLECTIVA,
              ].includes(session.principalRol as AdminPrincipalRol) && (
                <>
                  <div className="mt-4 user-info-tab">
                    <h4 className="text-20 text-regular text-purple800">
                      Coordinadora de Colectiva
                    </h4>
                    <hr className="mt-0 hr-pink" />
                    <div className="row">
                      {colectivaAdmins
                        .filter(
                          ({ principalRol }) =>
                            principalRol === AdminPrincipalRol.LIDER_COLECTIVA
                        )
                        .map((colectivaAdmin) => (
                          <div
                            className="user-info-tab__col col-md-4 col-sm-6 col-12"
                            key={colectivaAdmin._id}
                          >
                            <AdminCardProps
                              key={colectivaAdmin._id}
                              name={colectivaAdmin.username}
                              role="Coordinadora"
                              url={
                                colectivaAdmin._id === session._id
                                  ? '/inicio-coordinadora'
                                  : `/detalle-coordinadora/${colectivaAdmin._id}`
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="mt-4 user-info-tab">
                    <h4 className="text-20 text-regular text-purple800">
                      Acompañantes
                    </h4>
                    <hr className="mt-0 hr-pink" />
                    <div className="row">
                      {colectivaAdmins
                        .filter(
                          ({ principalRol }) =>
                            principalRol === AdminPrincipalRol.ACOMPANANTE
                        )
                        .map((colectivaAdmin) => (
                          <div
                            className="user-info-tab__col col-md-4 col-sm-6 col-12"
                            key={colectivaAdmin._id}
                          >
                            <AdminCardProps
                              key={colectivaAdmin._id}
                              name={colectivaAdmin.username}
                              role="Acompañante"
                              url={`/detalle-acompanante/${colectivaAdmin._id}`}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="mt-4 user-info-tab">
                    <h4 className="text-20 text-regular text-purple800">
                      Talleristas
                    </h4>
                    <hr className="mt-0 hr-pink" />
                    <div className="row">
                      {colectivaAdmins
                        .filter(
                          ({ principalRol }) =>
                            principalRol === AdminPrincipalRol.TALLERISTA
                        )
                        .map((colectivaAdmin) => (
                          <div
                            className="user-info-tab__col col-md-4 col-sm-6 col-12"
                            key={colectivaAdmin._id}
                          >
                            <AdminCardProps
                              key={colectivaAdmin._id}
                              name={colectivaAdmin.username}
                              role="Tallerista"
                              url={`/detalle-tallerista/${colectivaAdmin._id}`}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="mt-4 user-info-tab">
                    <h4 className="text-20 text-regular text-purple800">
                      Entregadoras
                    </h4>
                    <hr className="mt-0 hr-pink" />
                    <div className="row">
                      {colectivaAdmins
                        .filter(
                          ({ principalRol }) =>
                            principalRol === AdminPrincipalRol.ENTREGADORA
                        )
                        .map((colectivaAdmin) => (
                          <div
                            className="user-info-tab__col col-md-4 col-sm-6 col-12"
                            key={colectivaAdmin._id}
                          >
                            <AdminCardProps
                              key={colectivaAdmin._id}
                              name={colectivaAdmin.username}
                              role="Entregadora"
                              url={`/detalle-entregadora/${colectivaAdmin._id}`}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default ColectivaScreen;
