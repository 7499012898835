import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import AcompananteTableComponent from '../../../../components/Elements/DataTable/acompananteTable.component';
import AdminCardProps from '../../../../components/Elements/AdminCard/adminCard.component';
import TalleresComponent from '../../../../components/Elements/Talleres/talleres.component';
import { AdminPrincipalRol, IAdmin } from '../../../../types/adminInterface';
import { getAdmin, searchAdmins, updateAdmin } from '../../../../api/adminApi';
import { getSession } from '../../../../utils/helpers';
import LoaderComponent from '../../../../components/Loader/loader.component';
import AdminDataComponent from '../../../../components/Elements/Admins/adminData.component';
import { searchWorkshops } from '../../../../api/workshopApi';
import { IWorkshop } from '../../../../types/workshopInterface';
import EntregaTableComponent from '../../../../components/Elements/DataTable/entregaTable.component';
import { IUser } from '../../../../types/userInterface';

const CoordinadoraDetailScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState<Partial<IAdmin>>({});
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const [colectivaAdmins, setColectivaAdmins] = useState<IAdmin[]>([]);
  const [workshops, setWorkshops] = useState<IWorkshop[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [pagination, setPagination] = useState({
    totalRows: 0,
    page: 1,
    limit: 10,
  });

  const navigate = useNavigate();

  const { id: adminId } = useParams<{ id: string }>();

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchAdmin = async (id: string) => {
    try {
      setLoading(true);
      const session = getSession();
      if (!session || !session.token) {
        navigate('/login');
        return;
      }
      setSession(session);
      const response = await getAdmin(id, session.token);
      setAdmin(response);
      fetchColectivaAdmins(response.colectiva?.name || '');
      fetchColectivaWorkshops(response.colectiva?.name || '');
    } catch (error) {
      setError('Ocurrió un error al obtener los datos');
    } finally {
      setLoading(false);
    }
  };

  const saveAdmin = async (admin: Partial<IAdmin>, adminId: string) => {
    try {
      setLoading(true);
      const response = await updateAdmin(adminId, admin, session.token || '');
      setAdmin(response);
    } catch (error) {
      setError('Ocurrió un error al actualizar los datos');
    } finally {
      setLoading(false);
    }
  };

  const fetchColectivaAdmins = async (selectedColectiva: string) => {
    try {
      const session = getSession();
      if (!session) {
        return;
      }
      setSession(session);
      const response = await searchAdmins(
        0,
        100,
        { colectiva: { name: selectedColectiva } },
        session?.token || ''
      );

      setColectivaAdmins(response.docs);
    } catch (error) {
      setError(
        'Ocurrió un error al obtener los administradores de la colectiva'
      );
    }
  };

  const fetchColectivaWorkshops = async (selectedColectiva: string) => {
    try {
      const session = getSession();
      if (session) {
        const response = await searchWorkshops(
          session?.token || '',
          {
            page: 1,
            limit: 4,
          },
          {
            colectiva: selectedColectiva,
            adminId,
          }
        );
        setWorkshops(response.docs);
      }
    } catch (error) {
      setError('Ocurrió un error al obtener los talleres');
    }
  };

  useEffect(() => {
    if (adminId) {
      fetchAdmin(adminId);
    }
  }, [adminId]);

  return (
    <>
      <HeaderShared />
      {loading && <LoaderComponent />}
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom">
            <div className="row">
              <div className="col-12 mb-3">
                <a
                  href=""
                  className="btn--back"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="view-head__data col-12">
                {admin && session && (
                  <AdminDataComponent
                    session={session}
                    admin={admin}
                    setLoading={setLoading}
                    setError={setError}
                    saveAdmin={saveAdmin}
                  />
                )}
                <div className="row">
                  <div className="dashboard-info col-12">
                    {[admin.principalRol, admin.secondaryRol].includes(
                      AdminPrincipalRol.ENTREGADORA
                    ) && (
                      <div className="view-body module-top40">
                        <div className="row">
                          <div className="col-12  mb-4">
                            <h4 className="h3 mb-4">
                              Entregas asignadas esta semana
                            </h4>
                            <p className="text-24">
                              <strong>Entrega 20/Septiembre/2023</strong>
                            </p>
                            <EntregaTableComponent
                              users={users}
                              setUsers={setUsers}
                              pagination={pagination}
                              setPagination={setPagination}
                            />
                          </div>
                          <div className="view-body__button col-12  mb-4">
                            <p className="text-24">
                              <strong>Entrega 21/Septiembre/2023</strong>
                            </p>
                            <a href="#" className="btn btn--type2">
                              Ver listado de mujeres
                            </a>
                          </div>
                          <div className="view-body__button col-12  mb-4">
                            <p className="text-24">
                              <strong>Entrega 23/Septiembre/2023</strong>
                            </p>
                            <a href="#" className="btn btn--type2">
                              Ver listado de mujeres
                            </a>
                          </div>
                          <div className="view-body__button col-12  mb-4">
                            <p className="text-24">
                              <strong>Entrega 25/Septiembre/2023</strong>
                            </p>
                            <a href="#" className="btn btn--type2">
                              Ver listado de mujeres
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                    {[admin.principalRol, admin.secondaryRol].some((rol) =>
                      [
                        AdminPrincipalRol.LIDER_COLECTIVA,
                        AdminPrincipalRol.ACOMPANANTE,
                      ].includes(rol as AdminPrincipalRol)
                    ) && (
                      <>
                        <h4 className="mb-4">{`${
                          admin.principalRol ===
                          AdminPrincipalRol.LIDER_COLECTIVA
                            ? 'Mujeres en la colectiva'
                            : 'Mujeres acompañando'
                        }`}</h4>
                        <div className="col-12 col-reset mb-4">
                          <AcompananteTableComponent
                            searchText=""
                            userSearchTerms={{
                              colectiva: {
                                documentId: `${admin.colectiva?.id || ''}`,
                                value: admin.colectiva?.name || '',
                              },
                              assignedTo: [
                                admin.principalRol,
                                admin.secondaryRol,
                              ].includes(AdminPrincipalRol.ACOMPANANTE)
                                ? {
                                    adminId: admin._id,
                                  }
                                : undefined,
                            }}
                          />
                        </div>
                        <h4 className="mb-4">Integrantes en su colectiva</h4>
                        <div className="mt-4 user-info-tab">
                          <h4 className="text-20 text-regular text-purple800">
                            Acompañantes
                          </h4>
                          <hr className="mt-0 hr-pink" />
                          <div className="row">
                            {colectivaAdmins
                              .filter(
                                (admin) =>
                                  admin.principalRol ===
                                  AdminPrincipalRol.ACOMPANANTE
                              )
                              .map((admin, index) => (
                                <div
                                  className="user-info-tab__col col-md-3 col-sm-6 col-12"
                                  key={index}
                                >
                                  <AdminCardProps
                                    name={admin.username}
                                    role={admin.principalRol}
                                    subRole={admin.secondaryRol}
                                    url={`/detalle-acompanante/${admin._id}`}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="mt-4 user-info-tab">
                          <h4 className="text-20 text-regular text-purple800">
                            Talleristas
                          </h4>
                          <hr className="mt-0 hr-pink" />
                          <div className="row">
                            {colectivaAdmins
                              .filter(
                                (admin) =>
                                  admin.principalRol ===
                                  AdminPrincipalRol.TALLERISTA
                              )
                              .map((admin, index) => (
                                <div
                                  className="user-info-tab__col col-md-3 col-sm-6 col-12"
                                  key={index}
                                >
                                  <AdminCardProps
                                    name={admin.username}
                                    role={admin.principalRol}
                                    subRole={admin.secondaryRol}
                                    url={`/detalle-acompanante/${admin._id}`}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="mt-4 user-info-tab">
                          <h4 className="text-20 text-regular text-purple800">
                            Entregadoras
                          </h4>
                          <hr className="mt-0 hr-pink" />
                          {colectivaAdmins
                            .filter(
                              (admin) =>
                                admin.principalRol ===
                                AdminPrincipalRol.ENTREGADORA
                            )
                            .map((admin, index) => (
                              <div
                                className="user-info-tab__col col-md-3 col-sm-6 col-12"
                                key={index}
                              >
                                <AdminCardProps
                                  name={admin.username}
                                  role={admin.principalRol}
                                  subRole={admin.secondaryRol}
                                  url={`/detalle-acompanante/${admin._id}`}
                                />
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                    {[admin.principalRol, admin.secondaryRol].includes(
                      AdminPrincipalRol.TALLERISTA
                    ) && (
                      <div className="mt-4 user-info-tab">
                        <h4 className="text-20 text-regular text-purple800">
                          Talleres activos
                        </h4>
                        <hr className="mt-0 hr-pink" />
                        <div className={`row justify-content-start`}>
                          {workshops.map((workshop, index) => (
                            <TalleresComponent
                              key={index}
                              mode="ver-mas"
                              showAvailableSlotsBadge={false}
                              showStockList={false}
                              cols="col-xl-3"
                              workshop={workshop}
                              showChat={false}
                            />
                          ))}
                          <button
                            className="btn btn--type1"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`/talleres/${admin._id}`);
                            }}
                          >
                            Ver todos los talleres
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default CoordinadoraDetailScreen;
