import React, { useState, useEffect } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate, useParams } from 'react-router-dom';
import { differenceInDays, format } from 'date-fns';
import Swal from 'sweetalert2';
import { IWorkshop } from '../../../types/workshopInterface';
import LoaderComponent from '../../Loader/loader.component';
import {
  createWorkshop,
  getWorkshop,
  updateWorkshop,
} from '../../../api/workshopApi';
import { getSession } from '../../../utils/helpers';
import { IAdmin } from '../../../types/adminInterface';
import { publishMessage } from '../../../api/chatApi';
import BasicTimePicker from '../../Elements/TimePicker/timePircker.component';

const TallerCreateComponent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [workshop, setWorkshop] = useState<Partial<IWorkshop>>({});
  const [schedule, setSchedule] = useState({
    start: '',
    end: '',
  });
  const [isReschedule, setIsReschedule] = useState(false);

  const { id } = useParams<{ id: string }>();

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchWorkshop = async (workshopId: string) => {
    try {
      setLoading(true);
      if (!workshopId) {
        throw new Error('No se pudo obtener el id del taller');
      }
      const session = getSession();
      const response = await getWorkshop(workshopId, session?.token || '');
      setWorkshop(response);
    } catch (error) {
      setError('Ocurrió un error al obtener los datos del taller');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (
    id: string,
    session: IAdmin,
    setNotification: boolean
  ) => {
    setLoading(true);
    try {
      const result = await updateWorkshop(
        id,
        {
          name: workshop.name || '',
          workshopDate: workshop.workshopDate || '',
          workshopSchedule: workshop.workshopSchedule || '',
          workshopTextDate: workshop.workshopTextDate || '',
          workshopType: workshop.workshopType || '',
          adminId: session?._id || '',
          status: setNotification ? 'rescheduled' : 'active',
        },
        session?.token || ''
      );
      if (setNotification) {
        await publishMessage(
          {
            sender: session._id || '',
            senderName: session.username || '',
            message: `Tu taller ha sido reagendado a la fecha ${workshop.workshopTextDate}, horario ${workshop.workshopSchedule}`,
            roomId: workshop.chatRoomId || '',
            transactionId: Math.floor(Math.random() * 1000000),
          },
          session?.token || ''
        );
      }
      if (result._id) {
        navigate(`/confirmar-taller/${result._id}`);
      } else {
        setError('Ocurrió un error al crear el taller');
      }
    } catch (error) {
      setError('Ocurrió un error al actualizar el taller');
    } finally {
      setLoading(false);
    }
  };

  const checkAndUpdate = async () => {
    if (isReschedule) {
      return Swal.fire({
        title: '¿Estás segura de reagendar este taller?',
        text: `
          Una vez que cambies la fecha, las mujeres inscritas recibirán la notificación 
          en el chat y es probable que algunas de ellas no puedan asistir y tengan que 
          cambiar de fecha.
        `,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, actualizar',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await handleUpdate(id || '', getSession() as IAdmin, true);
        }
      });
    }
    return handleUpdate(id || '', getSession() as IAdmin, false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const session = getSession();
      if (id && session) {
        await checkAndUpdate();
      } else {
        setLoading(true);
        const { workshopDate } = workshop;
        const diffDays = differenceInDays(new Date(), workshopDate as Date);
        if (diffDays > 0) {
          return Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'La fecha debe ser mayor a la fecha actual',
          });
        }
        const colectiva = session?.colectiva?.name || '';
        const result = await createWorkshop(
          {
            ...workshop,
            adminId: session?._id || '',
            status: 'pending',
            colectiva,
          },
          session?.token || ''
        );
        if (result._id) {
          navigate(`/confirmar-taller/${result._id}`);
        } else {
          setError('Ocurrió un error al crear el taller');
        }
      }
    } catch (error) {
      setError('Ocurrió un error al crear el taller');
    } finally {
      setLoading(false);
    }
  };

  const canSubmit = () => {
    const {
      name,
      availablePlaces,
      workshopDate,
      workshopSchedule,
      workshopType,
    } = workshop;
    return (
      name &&
      availablePlaces &&
      workshopDate &&
      workshopSchedule &&
      workshopType
    );
  };

  const getWorkshopDate = (workshop: Partial<IWorkshop>) => {
    if (workshop.workshopDate instanceof Date) {
      return format(workshop.workshopDate, 'yyyy-MM-dd');
    }
    if (workshop.workshopDate) {
      return format(new Date(workshop.workshopDate), 'yyyy-MM-dd');
    }
    return '';
  };

  useEffect(() => {
    setIsButtonDisabled(!canSubmit());
  }, [workshop]);

  useEffect(() => {
    if (id) {
      fetchWorkshop(id);
    }
  }, [id]);

  return (
    <>
      <div className="form-block">
        {loading && <LoaderComponent />}
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label htmlFor="name" className={`form-label label--icon`}>
              Nombre del taller <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Nombre del taller</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="text"
              className={`form-control inputRequired`}
              id="name"
              name="name"
              placeholder="Ingresa el nombre del taller"
              value={workshop.name || ''}
              onChange={(e) =>
                setWorkshop((prevWorkshop) => ({
                  ...prevWorkshop,
                  name: e.target.value,
                }))
              }
            />
          </div>
          <div className="form-row">
            <label htmlFor="date" className={`form-label label--icon`}>
              Fecha del taller <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Fecha del taller</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="date"
              className={`form-control inputRequired`}
              placeholder="Ingresa tu correo electrónico"
              id="date"
              name="workshopDate"
              value={getWorkshopDate(workshop)}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length === 10) {
                  const splittedDate = value.split('-');
                  const selectedDate = new Date(
                    splittedDate[1] +
                      '/' +
                      splittedDate[2] +
                      '/' +
                      splittedDate[0]
                  );
                  if (selectedDate) {
                    if (workshop.workshopDate !== selectedDate) {
                      setIsReschedule(true);
                    }
                    setWorkshop((prevWorkshop) => ({
                      ...prevWorkshop,
                      workshopDate: selectedDate,
                      workshopTextDate: format(
                        selectedDate,
                        'EEEE dd MMMM, yyyy'
                      ),
                    }));
                  }
                }
              }}
            />
          </div>
          <div className="form-row">
            <label htmlFor="place" className={`form-label label--icon`}>
              Lugares disponibles
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Lugares disponibles</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="number"
              className={`form-control inputRequired`}
              id="place"
              name="place"
              placeholder="Ingresa los lugares disponibles"
              value={workshop.availablePlaces || ''}
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const selectedValue = e.target.value;
                setWorkshop((prevWorkshop) => ({
                  ...prevWorkshop,
                  availablePlaces: Number(selectedValue),
                }));
              }}
            />
          </div>
          <div className="form-row">
            <div className="row">
              <div className="col-12">
                <label htmlFor="time" className={`form-label label--icon`}>
                  Horario
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Horario</Tooltip>}
                  >
                    <span className="material-icons-outlined icon">
                      help_outline
                    </span>
                  </OverlayTrigger>
                </label>
                <div className="row">
                  <div className="col-6">
                    <BasicTimePicker
                      label="Inicio"
                      onChange={(value) => {
                        if (value) {
                          const time = format(value, 'HH:mm');
                          setSchedule((prevSchedule) => ({
                            ...prevSchedule,
                            start: time,
                          }));
                          if (schedule.end) {
                            setWorkshop((prevWorkshop) => ({
                              ...prevWorkshop,
                              workshopSchedule: `${time} - ${schedule.end} hrs.`,
                            }));
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <BasicTimePicker
                      label="Fin"
                      onChange={(value) => {
                        if (value) {
                          const time = format(value, 'HH:mm');
                          setSchedule((prevSchedule) => ({
                            ...prevSchedule,
                            end: time,
                          }));
                          if (schedule.start) {
                            setWorkshop((prevWorkshop) => ({
                              ...prevWorkshop,
                              workshopSchedule: `${schedule.start} - ${time} hrs.`,
                            }));
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <label htmlFor="modalidad" className={`form-label label--icon`}>
                  Modalidad
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Modalidad</Tooltip>}
                  >
                    <span className="material-icons-outlined icon">
                      help_outline
                    </span>
                  </OverlayTrigger>
                </label>
                <select
                  id="modalidad"
                  className="form-control form-select inputRequired"
                  value={workshop.workshopType || ''}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setWorkshop((prevWorkshop) => ({
                      ...prevWorkshop,
                      workshopType: selectedValue,
                    }));
                  }}
                >
                  <option value="">Elige una opción</option>
                  <option value="En línea">En línea</option>
                  <option value="Teléfono">Teléfono</option>
                  <option value="Presencial">Presencial</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={isButtonDisabled}
            >
              {id ? 'Actualizar' : 'Registrar'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default TallerCreateComponent;
