import api from './index';
import { IUser, IUserPaginate } from '../types/userInterface';

interface AxiosError {
  error: string;
}

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const updateUser = async (
  userId: string,
  user: Partial<IUser>,
  token: string
) => {
  const response = await api.put<Partial<IUser> | AxiosError>(
    `/admin-user/${userId}`,
    user,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as Partial<IUser>;
};

export const bulkUpdateUsers = async (
  users: Partial<IUser>[],
  token: string
) => {
  const response = await api.put<Partial<IUser>[] | AxiosError>(
    `/admin-user/bulk`,
    users,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as Partial<IUser>[];
};

export const getUser = async (userId: string, token: string) => {
  const response = await api.get<Partial<IUser> | AxiosError>(
    `/admin-user/${userId}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as Partial<IUser>;
};

export const getUsers = async (
  token: string,
  props: {
    page?: number;
    limit?: number;
    searchText?: string;
  }
) => {
  const { page, limit, searchText } = props;
  const response = await api.get<IUserPaginate | AxiosError>('/admin-user', {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
    params: {
      page,
      limit,
      searchText,
    },
  });
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IUserPaginate;
};

export const getUserFile = async (key: string, token: string) => {
  const response = await api.post<
    | {
        fileUrl: string;
      }
    | AxiosError
  >(
    `/admin-user/fetchFile`,
    { key },
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as { fileUrl: string };
};

export const searchUsers = async (
  token: string,
  user: Partial<IUser> & {
    notIds?: string[];
    possibleWorkshop?: string;
    createdAtRange?: { startDate: Date; endDate: Date };
    searchText?: string;
    stepGt?: number;
  },
  props?: {
    page?: number;
    limit?: number;
  }
) => {
  const page = props?.page || 1;
  const limit = props?.limit || 10;
  const response = await api.post<IUserPaginate | AxiosError>(
    `/admin-user/search`,
    user,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        limit,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IUserPaginate;
};
