import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import ReactSearchAutocomplete from 'react-search-autocomplete/dist/components/ReactSearchAutocomplete';
import AdminCardProps from '../../../components/Elements/AdminCard/adminCard.component';
import { AdminPrincipalRol, IAdmin } from '../../../types/adminInterface';
import { getSession } from '../../../utils/helpers';
import { searchAdmins } from '../../../api/adminApi';
import LoaderComponent from '../../../components/Loader/loader.component';

const TeamScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [adminsCoordinadoras, setAdminsCoordinadoras] = useState<IAdmin[]>([]);
  const [adminsAcompanantes, setAdminsAcompanantes] = useState<IAdmin[]>([]);
  const [adminsTalleristas, setAdminsTalleristas] = useState<IAdmin[]>([]);
  const [adminsEntregadoras, setAdminsEntregadoras] = useState<IAdmin[]>([]);

  const navigate = useNavigate();

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchAdmins = async () => {
    try {
      if (searchText === '') {
        setLoading(true);
      }

      const session = getSession();
      if (!session || !session.token) {
        navigate('/login');
        return;
      }

      const page = 1;
      const limit = 4;

      const coordinadoras = await searchAdmins(
        page,
        limit,
        { principalRol: AdminPrincipalRol.LIDER_COLECTIVA, searchText },
        session.token
      );
      const acompanantes = await searchAdmins(
        page,
        limit,
        { principalRol: AdminPrincipalRol.ACOMPANANTE, searchText },
        session.token
      );
      const talleristas = await searchAdmins(
        page,
        limit,
        { principalRol: AdminPrincipalRol.TALLERISTA, searchText },
        session.token
      );
      const entregadoras = await searchAdmins(
        page,
        limit,
        { principalRol: AdminPrincipalRol.ENTREGADORA, searchText },
        session.token
      );

      setAdminsCoordinadoras(coordinadoras.docs);
      setAdminsAcompanantes(acompanantes.docs);
      setAdminsTalleristas(talleristas.docs);
      setAdminsEntregadoras(entregadoras.docs);
      setLoading(false);
    } catch (error) {
      setError('Error al obtener los administradores');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, [searchText]);

  return (
    <>
      <HeaderShared />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row mb-4">
          <div className="col-12">
            <div className="auth-row row">
              <div className="col-12 mb-4">
                <h1>Equipo de Las Amigas</h1>
              </div>
              <div className="col-12 mb-4">
                <a href="/detalle-acompanante" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-12">
                <h2 className="h3">Busca entre Las Amigas</h2>
                <div className="form-search-complete">
                  <div className="autocomplete-search search--100">
                    <ReactSearchAutocomplete
                      items={[]}
                      autoFocus
                      onSearch={(keyword) => setSearchText(keyword)}
                      placeholder="Buscar colectiva"
                      className="autocomplete-search__input"
                      showItemsOnFocus={false}
                      showNoResults={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mt-4 user-info-tab">
            <h4 className="text-20 text-regular text-purple800">
              Coordinadoras de Colectiva
            </h4>
            <hr className="mt-0 hr-pink" />
            <div className="row">
              {adminsCoordinadoras.map((admin, index) => (
                <div
                  className="user-info-tab__col col-md-3 col-sm-6 col-12"
                  key={index}
                >
                  <AdminCardProps
                    name={admin.username}
                    role={admin.principalRol}
                    url={`/detalle-coordinadora/${admin._id}`}
                    title="h3"
                    colectiva={admin.colectiva?.name || ''}
                  />
                </div>
              ))}
              <div className="d-flex col-12 justify-content-center mt-3">
                <Link to="/coordinadoras" className="btn btn--type2">
                  Ver a todas las coordinadoras de colectiva
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-4 user-info-tab">
            <h4 className="text-20 text-regular text-purple800">
              Acompañantes
            </h4>
            <hr className="mt-0 hr-pink" />
            <div className="row">
              {adminsAcompanantes.map((admin, index) => (
                <div
                  className="user-info-tab__col col-md-3 col-sm-6 col-12"
                  key={index}
                >
                  <AdminCardProps
                    name={admin.username}
                    role={admin.principalRol}
                    subRole={admin.secondaryRol}
                    url={`/detalle-acompanante/${admin._id}`}
                    title="h3"
                    colectiva={admin.colectiva?.name || ''}
                  />
                </div>
              ))}
              <div className="d-flex col-12 justify-content-center mt-3">
                <Link to="/acompanantes" className="btn btn--type2">
                  Ver a todas las acompañantes
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-4 user-info-tab">
            <h4 className="text-20 text-regular text-purple800">Talleristas</h4>
            <hr className="mt-0 hr-pink" />
            <div className="row">
              {adminsTalleristas.map((admin, index) => (
                <div
                  className="user-info-tab__col col-md-3 col-sm-6 col-12"
                  key={index}
                >
                  <AdminCardProps
                    name={admin.username}
                    role={admin.principalRol}
                    subRole={admin.secondaryRol}
                    url={`/detalle-tallerista/${admin._id}`}
                    title="h3"
                    colectiva={admin.colectiva?.name || ''}
                  />
                </div>
              ))}
              <div className="d-flex col-12 justify-content-center mt-3">
                <Link to="/talleristas" className="btn btn--type2">
                  Ver a todas las talleristas
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-4 user-info-tab">
            <h4 className="text-20 text-regular text-purple800">
              Entregadoras
            </h4>
            <hr className="mt-0 hr-pink" />
            <div className="row">
              {adminsEntregadoras.map((admin, index) => (
                <div
                  className="user-info-tab__col col-md-3 col-sm-6 col-12"
                  key={index}
                >
                  <AdminCardProps
                    name={admin.username}
                    role={admin.principalRol}
                    subRole={admin.secondaryRol}
                    url={`/detalle-entregadora/${admin._id}`}
                    title="h3"
                    colectiva={admin.colectiva?.name || ''}
                  />
                </div>
              ))}
              <div className="d-flex col-12 justify-content-center mt-3">
                <Link to="/entregadoras" className="btn btn--type2">
                  Ver a todas las entregadoras
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default TeamScreen;
