import React from 'react';
import TallerItemDateComponent from '../Buttons/tallerItem.component';
import { IWorkshop } from '../../../types/workshopInterface';
import { IAdmin } from '../../../types/adminInterface';

interface TallerItemDateComponentProps {
  workshops: IWorkshop[];
}

const TalleresListDateComponent: React.FC<TallerItemDateComponentProps> = ({
  workshops,
}) => {
  return (
    <div className="talleres-list">
      <ul>
        {workshops.map((taller, index) => (
          <li key={index}>
            <TallerItemDateComponent
              title={taller.name}
              organizer={(taller.adminId as IAdmin).username}
              date={taller.workshopTextDate}
              url={`/talleres-asistentes/${taller._id}`}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TalleresListDateComponent;
