import React, { useState, useEffect } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CustomPagination from '../Paginator/paginator.component';
import { IUser, UserDeliverStatus } from '../../../types/userInterface';
import { differenceInDays, format } from 'date-fns';
import BasicTimePicker from '../TimePicker/timePircker.component';
import { IWorkshop } from '../../../types/workshopInterface';
interface Props {
  users: IUser[];
  setUsers: React.Dispatch<React.SetStateAction<IUser[]>>;
  pagination: {
    totalRows: number;
    page: number;
    limit: number;
  };
  setPagination: React.Dispatch<
    React.SetStateAction<{
      totalRows: number;
      page: number;
      limit: number;
    }>
  >;
  readonly?: boolean;
}

const EntregaTableComponent: React.FC<Props> = ({
  users,
  setUsers,
  pagination,
  setPagination,
  readonly = false,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1200);

  const columns: TableColumn<IUser>[] = [
    {
      name: 'No. Ficha',
      selector: (row) => row.userId,
    },
    {
      name: 'Nombre',
      selector: (row) => row.username,
      format: (row) => {
        return (
          <Link
            to={`/detalle-usuario/${row._id}`}
            className={`text-purple500 text-uppercase ${
              !row.assignedTo || !row.assignedTo.adminId ? 'new' : ''
            }`}
          >
            <strong>{row.username}</strong>
          </Link>
        );
      },
    },
    {
      name: 'Semanas',
      selector: (row) =>
        row.saludGinecologica.pregnancyCalculator?.result || 'N/A',
    },
    {
      name: 'Derivada de',
      selector: (row) => row.derivada?.derivadaA || 'N/A',
    },
    {
      name: 'Apuntes',
      selector: (row) =>
        (
          (row.workshopSelected?.workshopId as IWorkshop)?.assignedUsers || []
        ).find((user) => user.userId === row._id)?.apuntes || 'N/A',
      format: (row) => formatFolleto(row),
    },
    {
      name: 'Entrega',
      selector: (row) => row.assignedDeliverer?.status || 'N/A',
      format: (row) => formatEntrega(row),
    },
    {
      name: 'Horario',
      selector: (row) =>
        row.assignedDeliverer?.schedule
          ? format(row.assignedDeliverer?.schedule, 'hh:mm aa')
          : '',
      format: (row) => formatSchedule(row),
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: IUser) =>
        differenceInDays(new Date(), new Date(row.createdAt)) < 12,
      style: {
        backgroundColor: '#FCEEF2',
      },
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const updateMedia = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  const formatFolleto = (row: IUser) => {
    const apuntes =
      (
        (row.workshopSelected?.workshopId as IWorkshop)?.assignedUsers || []
      ).find((user) => user.userId === row._id)?.apuntes || 'N/A';
    return (
      <select
        className="form-control form-select form--medium"
        defaultValue={apuntes || ''}
        value={apuntes || ''}
        disabled={true}
        onChange={(e) => {
          const newUsers = users.map((user) => {
            if (user._id === row._id) {
              user.assignedDeliverer = {
                ...user.assignedDeliverer,
                folleto: Number(e.target.value || '') || 0,
              };
            }
            return user;
          });
          setUsers([...newUsers]);
        }}
      >
        <option value=""> - </option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </select>
    );
  };

  const formatEntrega = (row: IUser) => {
    return (
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value={row.status}
          id={`estado-${row._id}`}
          disabled={readonly}
          checked={
            row.assignedDeliverer?.status === UserDeliverStatus.DELIVERED
          }
          onChange={(e) => {
            const newUsers = users.map((user) => {
              if (user._id === row._id) {
                user.assignedDeliverer = {
                  ...user.assignedDeliverer,
                  status: e.target.checked
                    ? UserDeliverStatus.DELIVERED
                    : UserDeliverStatus.PENDING,
                };
              }
              return user;
            });
            setUsers([...newUsers]);
          }}
        />
        <label className="form-check-label" htmlFor={`estado-${row._id}`}>
          {row.assignedDeliverer?.status === UserDeliverStatus.PENDING ||
          row.assignedDeliverer?.status === UserDeliverStatus.REJECTED
            ? 'Entregar'
            : 'Entregado'}
        </label>
      </div>
    );
  };

  const formatSchedule = (row: IUser) => {
    const schedule = row.assignedDeliverer?.schedule;
    return (
      <div className="data-table__time">
        <BasicTimePicker
          label=""
          onChange={(value) => {
            if (value) {
              const newUsers = users.map((user) => {
                if (user._id === row._id) {
                  user.assignedDeliverer = {
                    ...user.assignedDeliverer,
                    schedule: value,
                  };
                }
                return user;
              });
              setUsers([...newUsers]);
            }
          }}
          value={schedule ? new Date(schedule) : undefined}
          disabled={readonly}
        />
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="data-table">
      {!isMobileView ? (
        <DataTable
          columns={columns}
          data={users}
          conditionalRowStyles={conditionalRowStyles}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationComponent={(props) => {
            const handleChangePage = (page: number) => {
              setPagination({ ...pagination, page });
              console.log('Changing page to', page);
              // Asume que props.onChangePage realmente espera un solo argumento.
              // Esto es para diagnóstico; revisa la consola para ver qué se registra cuando cambias de página.
              (props.onChangePage as (page: number) => void)(page);
            };
            return (
              <div className="data-paginator">
                <CustomPagination
                  rowsPerPage={props.rowsPerPage}
                  rowCount={props.rowCount}
                  onChangePage={handleChangePage}
                  currentPage={currentPage}
                />
              </div>
            );
          }}
          onChangePage={(page) => setCurrentPage(page)}
          paginationPerPage={pagination.limit}
          paginationTotalRows={pagination.totalRows}
        />
      ) : (
        <section className="data-table-mobile">
          <div className="data-table-mobile__header">
            <p>
              <strong>Nombre</strong>
            </p>
          </div>
          <Accordion className="data-table-mobile__accordion">
            {users.map((row, index) =>
              (() => {
                return (
                  <Accordion.Item eventKey={String(index)} key={row._id}>
                    <Accordion.Header>{row.username}</Accordion.Header>
                    <Accordion.Body>
                      <table>
                        <tr>
                          <td>
                            <strong>No. Ficha:</strong>
                          </td>
                          <td className="text-center">{row.userId}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Semanas:</strong>
                          </td>
                          <td className="text-center">
                            {row.saludGinecologica.pregnancyCalculator
                              ?.result || 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Derivada de:</strong>
                          </td>
                          <td className="text-center">
                            {row.derivada?.derivadaA || 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Folleto:</strong>
                          </td>
                          <td className="text-center">{formatFolleto(row)}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Entrega:</strong>
                          </td>
                          <td className="text-center">{formatEntrega(row)}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Horario:</strong>
                          </td>
                          <td className="text-center">{formatSchedule(row)}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="text-center">
                            <a
                              href="/detalle-usuario"
                              className="btn btn--type2"
                            >
                              <strong>Ver ficha</strong>
                            </a>
                          </td>
                        </tr>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })()
            )}
          </Accordion>
        </section>
      )}
    </div>
  );
};

export default EntregaTableComponent;
