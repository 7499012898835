import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { deleteSession, getSession } from '../../utils/helpers';
import { AdminPrincipalRol, IAdmin } from '../../types/adminInterface';

const HeaderShared: React.FC = () => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const [isOpen, setIsOpen] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isNotificationOn, setNotificationOn] = useState(true);

  const handleButtonClick = () => {
    setIsOpen(!isOpen); // Cambia el estado al opuesto de lo que está actualmente
  };
  const location = useLocation();

  const isActive = (path: string): string => {
    return location.pathname === path ? 'active' : '';
  };

  useEffect(() => {
    const session = getSession();
    if (session) {
      setIsLogged(true);
      setAdmin(session);
    }
  }, []);

  const homeNavigate = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    const { principalRol } = admin;
    console.log('principalRol', admin);
    switch (principalRol) {
      case AdminPrincipalRol.ADMIN:
        navigate('/inicio-administrador');
        break;
      case AdminPrincipalRol.TALLERISTA:
        navigate('/inicio-tallerista');
        break;
      case AdminPrincipalRol.ACOMPANANTE:
        navigate('/inicio-acompanante');
        break;
      case AdminPrincipalRol.ENTREGADORA:
        navigate('/inicio-entregadora');
        break;
      case AdminPrincipalRol.LIDER_COLECTIVA:
        navigate('/inicio-coordinadora');
        break;
      default:
        navigate('/');
        break;
    }
  };

  const handleLogOut = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    deleteSession();
    setTimeout(() => {
      window.location.href = '/';
    }, 100);
  };

  return (
    <header>
      <div className="container">
        <div className="header__col col-12">
          <a className="header__logo" onClick={homeNavigate}>
            <figure>
              <img
                src={`${
                  isOpen
                    ? '/assets/images/logo/salud-logo-white.png'
                    : '/assets/images/logo/salud-logo.png'
                }`}
                alt="Red de Salud Comunitaria"
              />
            </figure>
          </a>
          <nav className={`main-nav ${isOpen ? 'active' : ''}`}>
            <ul>
              {isLogged ? (
                <li className="main-nav__mobile">
                  <div className="header-options__mobile">
                    <div className="header-options__top">
                      {isNotificationOn ? (
                        <a href="/" className="notifications">
                          <i className="icon icon--notification-on-white"></i>
                        </a>
                      ) : (
                        ''
                      )}
                      <a href="/" className="user-control-button">
                        <i className="icon icon--user-small"></i>
                      </a>
                    </div>
                    {isNotificationOn ? (
                      <div className="header-alert justify-content-center">
                        <span className="icon material-icons-outlined">
                          report_problem
                        </span>
                        <p>Alguien más accedió a mi perfil</p>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </li>
              ) : (
                ''
              )}
              {isLogged ? (
                <>
                  {[
                    AdminPrincipalRol.LIDER_COLECTIVA,
                    AdminPrincipalRol.ACOMPANANTE,
                    AdminPrincipalRol.TALLERISTA,
                    AdminPrincipalRol.ENTREGADORA,
                  ].includes(admin.principalRol as AdminPrincipalRol) && (
                    <li>
                      <Link
                        to="/mi-colectiva"
                        className={`
                          ${isActive('/mi-colectiva')} 
                          ${isActive('/inicio-coordinadora')}
                        `}
                      >
                        Mi Colectiva
                      </Link>
                    </li>
                  )}
                  {[admin.principalRol, admin.secondaryRol].includes(
                    AdminPrincipalRol.ENTREGADORA
                  ) && (
                    <li>
                      <a
                        href="/mujeres-entregando"
                        className={`
                          ${isActive('/inicio-entregadora')}
                          ${isActive('/mujeres-entregando')}
                        `}
                      >
                        Mujeres Entregando
                      </a>
                    </li>
                  )}
                  {[admin.principalRol, admin.secondaryRol].includes(
                    AdminPrincipalRol.ACOMPANANTE
                  ) && (
                    <li>
                      <a
                        href="/mujeres-acompanando"
                        className={`
                          ${isActive('/mujeres-acompanando')}
                          ${isActive('/inicio-acompanante')}
                        `}
                      >
                        Mujeres Acompañando
                      </a>
                    </li>
                  )}
                  {[admin.principalRol, admin.secondaryRol].includes(
                    AdminPrincipalRol.TALLERISTA
                  ) && (
                    <>
                      <li>
                        <a
                          href="/talleres"
                          className={`${isActive('/talleres')} ${isActive(
                            '/inicio-tallerista'
                          )}`}
                        >
                          Talleres
                        </a>
                      </li>
                    </>
                  )}
                  {[AdminPrincipalRol.ADMIN].includes(
                    admin.principalRol as AdminPrincipalRol
                  ) && (
                    <>
                      <li>
                        <a
                          href="/colectivas"
                          className={isActive('/colectivas')}
                        >
                          Colectivas
                        </a>
                      </li>
                      <li>
                        <a
                          href="/equipo-de-las-amigas"
                          className={isActive('/equipo-de-las-amigas')}
                        >
                          Equipo Amigas
                        </a>
                      </li>
                      <li>
                        <a href="/mujeres" className={isActive('/mujeres')}>
                          Mujeres
                        </a>
                      </li>
                      <li>
                        <a
                          href="/documentos"
                          className={isActive('/documentos')}
                        >
                          Documentos
                        </a>
                      </li>
                      <li className="main-nav__mobile">
                        <a
                          href="/"
                          onClick={(event) => handleLogOut(event)}
                          className={isActive('/')}
                        >
                          <strong>Cerrar sesión</strong>
                        </a>
                      </li>
                    </>
                  )}
                </>
              ) : (
                <li>
                  <a href="/" className={isActive('/')}>
                    Iniciar sesión
                  </a>
                </li>
              )}
              {[
                AdminPrincipalRol.ADMIN,
                AdminPrincipalRol.LIDER_COLECTIVA,
              ].includes(admin.principalRol as AdminPrincipalRol) && (
                <li>
                  <a
                    href="/nueva-amiga"
                    className={`btn btn--type1 ${isActive(
                      '/registrar-una-mujer'
                    )}`}
                  >
                    Registrar una mujer
                  </a>
                </li>
              )}
              {isLogged ? (
                <>
                  <li className="main-nav__mobile">
                    <a
                      href="/"
                      onClick={(event) => handleLogOut(event)}
                      className={isActive('/')}
                    >
                      <strong>Cerrar sesión</strong>
                    </a>
                  </li>
                </>
              ) : (
                ''
              )}
            </ul>
          </nav>
          {isLogged ? (
            <div className="header__options">
              {/* <a href="/" className="icon-header ">
                <i className="icon icon--message"></i>
              </a> */}
              {/* <a href="/" className="icon-header ">
                    <i className="icon icon--notification-on"></i>
              </a> */}
              <div className="user-control">
                <button type="button" className="user-control-button">
                  <i className="icon icon--admin-small"></i>
                </button>
                <div className="user-options">
                  <div className="user-options__top">
                    <i className="icon icon--user-big"></i>
                    <article>
                      <h3 className="text-regular text-20">{`${
                        admin.username || ''
                      }`}</h3>
                      <Link to="/mi-perfil">Ver perfil</Link>
                    </article>
                  </div>
                  <div className="user-options__bottom">
                    <ul>
                      <li>
                        <a href="/" onClick={(event) => handleLogOut(event)}>
                          <i className="icon icon--logout"></i>
                          <span>Cerrar sesión</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <button
            type="button"
            className={`open-nav ${isOpen ? 'active' : ''}`}
            onClick={handleButtonClick}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default HeaderShared;
