import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import UserCardComponent from '../../../components/Elements/UserCard/userCard.component';
import { IAdmin } from '../../../types/adminInterface';
import { IUser, UserSteps } from '../../../types/userInterface';
import LoaderComponent from '../../../components/Loader/loader.component';
import { getSession } from '../../../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { searchUsers } from '../../../api/usersApi';
import { format, subDays } from 'date-fns';

const AccompanimentScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<IUser[]>([]);

  const navigate = useNavigate();

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchUsers = async (session: Partial<IAdmin>) => {
    try {
      setLoading(true);
      const startDate = subDays(new Date(), 7);
      const endDate = new Date();
      const response = await searchUsers(session.token || '', {
        colectiva: {
          value: session.colectiva?.name || '',
        },
        step: UserSteps.ACOMPANAMIENTO,
        createdAtRange: {
          startDate,
          endDate,
        },
      });
      setUsers(response.docs || []);
    } catch (error) {
      setError('Error al cargar los usuarios');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const sessionData = getSession();
    if (sessionData) {
      fetchUsers(sessionData);
    } else {
      navigate('/login');
    }
  }, []);
  return (
    <>
      <HeaderShared />
      {loading && <LoaderComponent />}
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom">
            <div className="row">
              <div className="col-12 mb-4">
                <a href="/inicio-acompanante" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              {!users.length && (
                <>
                  <div className="col-12 d-flex justify-content-center">
                    <figure className="image-179">
                      <img
                        src="/assets/images/illustrations/404.png"
                        alt="No tienes nuevos acompañamientos asignados"
                      />
                    </figure>
                  </div>
                  <div className="col-12 text-center mt-4">
                    <article>
                      <h1 className="h3 text-regular">
                        No tienes nuevos acompañamientos asignados
                      </h1>
                      <p>
                        Pronto llegarán nuevas mujeres a las que podrás seguir
                        ayudando.
                      </p>
                      <a href="/inicio-acompanante" className="btn btn--type1">
                        Ir al inicio
                      </a>
                    </article>
                  </div>
                </>
              )}
              {users.length > 0 && (
                <div className="col-12 col-md-8 mx-auto mt-3">
                  <article className="text-center mb-4">
                    <h1 className="h2 ">Nuevas mujeres para acompañar</h1>
                    <p className="text-20">
                      Se te{' '}
                      {`${
                        users.length > 1
                          ? 'asignaron nuevos acompañamientos'
                          : 'asignó 1 nuevo acompañamiento'
                      }`}
                    </p>
                  </article>
                  <div className="row justify-content-center">
                    {users.map((user, index) => (
                      <div key={index} className="col-md-6 col-12">
                        <UserCardComponent
                          name={user.username}
                          id={`${user.userId}`}
                          registrationDate={format(
                            new Date(user.createdAt),
                            'dd/MM/yyyy'
                          )}
                          weeks={
                            user.saludGinecologica.pregnancyCalculator
                              ?.result || ''
                          }
                          origin={user.derivada.derivadaA || ''}
                          cause={user.derivada.detalle || ''}
                          commune={user.colectiva.value || ''}
                          derivationReasons={user.derivada.motivo || []}
                          referringProfessional={''}
                          detailUrl={`/detalle-usuario/${user._id}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default AccompanimentScreen;
