import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

interface NotDelivered {
  reasons: string[];
  whatToDo: string;
  observations: string;
}
interface ModalEntregasProps {
  show: boolean;
  onHide: () => void;
  onConfirm: (notDelivered: NotDelivered) => Promise<void>;
}
const ModalEntregasComponents: React.FC<ModalEntregasProps> = ({
  show,
  onHide,
  onConfirm,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [notDelivered, setNotDelivered] = useState<NotDelivered>({
    reasons: [],
    whatToDo: '',
    observations: '',
  });

  const reasons: string[] = [
    'Esperé mucho tiempo y no llegaron todas las mujeres',
    'Estuvo en peligro mi integridad durante la entrega',
    'Las mujeres a las que no les entregué me cancelaron en último momento',
    'Otras razones',
  ];
  const whatToDoOptions: string[] = [
    'Derivarlas a un nuevo listado de entrega',
    'Que lo decida la acompañante respectiva',
  ];

  const handleUpdateReason = (reason: string, checked: boolean) => {
    const updatedReasons = [...notDelivered.reasons];
    if (!checked) {
      updatedReasons.splice(updatedReasons.indexOf(reason), 1);
    } else {
      updatedReasons.push(reason);
    }
    setNotDelivered({
      ...notDelivered,
      reasons: [...updatedReasons],
    });
  };

  const checkFormValidity = () => {
    const requiredFields = document.querySelectorAll('.requiredField');
    const allFieldsFilled = Array.from(requiredFields).every((field) => {
      const inputField = field as HTMLInputElement; // Aserción de tipo a HTMLInputElement

      if (inputField.type === 'checkbox' || inputField.type === 'radio') {
        return Array.from(requiredFields).some((f) => {
          const input = f as HTMLInputElement;
          return input.name === inputField.name && input.checked;
        });
      }
      return inputField.value.trim() !== '';
    });
    setIsFormValid(allFieldsFilled);
  };

  useEffect(() => {
    // Llama a checkFormValidity cuando el modal se muestra
    if (show) {
      checkFormValidity();
    }
  }, [notDelivered, show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="col-12 d-flex justify-content-center mb-4">
            <i className="icon icon--derivar"></i>
          </div>
          <h2 className="text-40 text-center text-500 pb-2">
            No se entregaron todos los kits
          </h2>
          <p className="text-24 text-center">
            Recuerda registrar los detalles por los que concluyes la entrega de
            forma incompleta
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-row">
            <label className="form-label label--icon">
              Elige el o los motivos por los que no se completó la entrega del
              listado <small className="required">*</small>
            </label>
            <p className="text-gray800">
              Puedes seleccionar más de una opción si es tu caso
            </p>
            <div className="row-check-2 row">
              {reasons.map((reason, index) => (
                <div className="row-check-2__col col-6" key={index}>
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="checkbox"
                      name="reasons"
                      id={`reason-${index}`}
                      value={reason}
                      checked={
                        notDelivered.reasons.length > 0 &&
                        notDelivered.reasons.includes(reason)
                      }
                      onChange={(e) => {
                        const checked = e.target.checked;
                        handleUpdateReason(reason, checked);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`reason-${index}`}
                    >
                      {reason}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
              ¿Qué deseas hacer con las entregas no realizadas?
            </label>
            <div className="form-check-multi">
              {whatToDoOptions.map((option, index) => (
                <div className="form-check" key={index}>
                  <input
                    className="form-check-input requiredField"
                    type="radio"
                    name="whatToDo"
                    id={`whatToDo-${index}`}
                    value={option}
                    checked={notDelivered.whatToDo === option}
                    onChange={(e) => {
                      const value = e.target.value;
                      setNotDelivered({
                        ...notDelivered,
                        whatToDo: value,
                      });
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`whatToDo-${index}`}
                  >
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="form-row">
            <label className="form-label">
              ¿Cuáles son los motivos adicionales de la derivación?
            </label>
            <textarea
              className="form-control"
              placeholder="Ingresa los motivos adicionales"
              id="motivos"
              onChange={(e) => {
                setNotDelivered({
                  ...notDelivered,
                  observations: e.target.value,
                });
              }}
            ></textarea>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-3">
          <button
            type="submit"
            className="btn btn--type1 btn--370"
            onClick={(e) => {
              e.preventDefault();
              onConfirm(notDelivered);
            }}
            disabled={!isFormValid}
          >
            Confirmar entrega
          </button>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn--type3 btn--370"
            onClick={onHide}
          >
            Cancelar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalEntregasComponents;
