import React, { useState } from 'react';
import HeaderShared from '../../shared/Header/header.shared';
import FooterShared from '../../shared/Header/footer.shared';

const NewColectivaSuccessScreen: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userLogged, setUserLogged] = useState(false);
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom40 row">
              <div className="col-lg-10 col-12 mx-auto text-center">
                <div className="d-flex justify-content-center">
                  <figure className="image-280">
                    <img
                      src="/assets/images/illustrations/share.png"
                      alt="Confirmación"
                    />
                  </figure>
                </div>
                <h1 className="text-40 mb-3">¡ Colectiva creada con éxito !</h1>
                <p>
                  Una nueva colectiva fue registrada y ya se encuentra
                  disponible para agregar mujeres en ella.
                </p>
              </div>
            </div>
            <div className="form-row  form--last">
              <a href="/colectivas" className="btn btn--type1 btn--270">
                Ir al Inicio
              </a>
            </div>
          </div>
        </div>
      </section>

      <FooterShared />
    </>
  );
};

export default NewColectivaSuccessScreen;
