import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { IUser, UserSteps } from '../../../../types/userInterface';
import { fetchStrapiDocs } from '../../../../api/strapi';
import { strapiDoc } from '../../../../types/strapiDocs';
import Swal from 'sweetalert2';
import { searchAdmins } from '../../../../api/adminApi';
import { getSession } from '../../../../utils/helpers';
import { AdminPrincipalRol, IAdmin } from '../../../../types/adminInterface';

interface ModalDerivarProps {
  user: Partial<IUser>;
  show: boolean;
  onHide: () => void;
  updateAmiga?: (updatedUser: Partial<IUser>) => Promise<void>;
}
const ModalDerivarComponents: React.FC<ModalDerivarProps> = ({
  show,
  onHide,
  user,
  updateAmiga,
}) => {
  const [personalData, setPersonalData] = useState<Partial<IUser>>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [motivosDerivar, setMotivosDerivar] = useState<strapiDoc[]>([]);
  const [admins, setAdmins] = useState<IAdmin[]>([]);

  const checkFormValidity = () => {
    const requiredFields = document.querySelectorAll('.requiredField');
    const allFieldsFilled = Array.from(requiredFields).every((field) => {
      const inputField = field as HTMLInputElement; // Aserción de tipo a HTMLInputElement

      if (inputField.type === 'checkbox' || inputField.type === 'radio') {
        return Array.from(requiredFields).some((f) => {
          const input = f as HTMLInputElement;
          return input.name === inputField.name && input.checked;
        });
      }
      return inputField.value.trim() !== '';
    });
    setIsFormValid(allFieldsFilled);
  };

  const getMotivosDerivar = async () => {
    try {
      const response = await fetchStrapiDocs('motivos-derivar', {
        sort: ['nombre:asc'],
      });
      setMotivosDerivar(response.data);
    } catch (error) {
      console.error('Error al obtener los motivos de derivar:', error);
      return [];
    }
  };

  const fetchAdmins = async () => {
    try {
      const session = getSession();
      const result = await searchAdmins(
        0,
        100,
        {
          colectiva: { name: session?.colectiva?.name },
          principalRol: AdminPrincipalRol.ACOMPANANTE,
          secondaryRol: AdminPrincipalRol.ACOMPANANTE,
        },
        session?.token || ''
      );
      if (result && result.docs.length) {
        setAdmins(result.docs);
      }
    } catch (error) {
      console.error(
        'Error al obtener los datos de los administradores:',
        error
      );
    }
  };

  const handleOnConfirm = async () => {
    try {
      if (updateAmiga) {
        onHide();
        await updateAmiga({
          _id: personalData._id,
          derivada: {
            ...personalData.derivada,
            createdAt: new Date().toISOString(),
          },
          assignedTo: {
            ...personalData.assignedTo,
          },
          step: UserSteps.ACOMPANAMIENTO,
        });
        Swal.fire({
          icon: 'success',
          title: 'Derivación exitosa',
          text: 'La amiga ha sido derivada a otra profesional con éxito.',
        });
      } else {
        console.error('updateAmiga no está definido');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al derivar',
        text: 'Ha ocurrido un error al derivar a otra profesional. Por favor, inténtalo de nuevo más tarde.',
      });
    }
  };

  useEffect(() => {
    if (user) {
      setPersonalData(user);
      getMotivosDerivar();
      fetchAdmins();
    }
  }, [user]);

  useEffect(() => {
    // Llama a checkFormValidity cuando el modal se muestra
    if (show) {
      checkFormValidity();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="col-12 d-flex justify-content-center mb-4">
            <i className="icon icon--derivar"></i>
          </div>
          <h2 className="text-40 text-center text-500 pb-2">
            Derivar a otra profesional
          </h2>
          <p className="text-24 text-center">
            Recuerda registrar los detalles por los que la mujer necesita ser
            derivada a otra profesional
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-row">
            <label className="form-label label--icon">
              ¿Hacia dónde deseas que se derive este perfil?
            </label>
            <div className="form-check-multi">
              {[
                { nombre: 'Profesional de Las Amigas', value: 'lasAmigas' },
                { nombre: 'A profesionales de OLA', value: 'ola' },
                { nombre: 'A otra colectiva o OIRS', value: 'oirs' },
              ].map((derivarA) => {
                return (
                  <div className="form-check" key={derivarA.value}>
                    <input
                      className="form-check-input requiredField"
                      type="radio"
                      name="derivar"
                      id={derivarA.value}
                      value={derivarA.value}
                      defaultChecked={
                        personalData.derivada?.derivadaA === derivarA.value
                      }
                      onChange={(event) => {
                        setPersonalData({
                          ...personalData,
                          derivada: {
                            ...personalData.derivada,
                            derivadaA: event.target.value,
                          },
                        });
                        checkFormValidity();
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={derivarA.value}
                    >
                      {derivarA.nombre}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          {personalData.derivada?.derivadaA === 'lasAmigas' && (
            <>
              <div className="form-row">
                <label className="form-label label--icon">
                  Selecciona a la profesional de Las Amigas a la que la quieras
                  derivar <small className="required">*</small>
                </label>
                <select
                  className="form-select requiredField"
                  aria-label="Selecciona a la profesional de Las Amigas a la que la quieras derivar"
                  defaultValue={'default'}
                  name="profesional"
                  onChange={(event) => {
                    setPersonalData({
                      ...personalData,
                      assignedTo: {
                        adminId: event.target.value,
                        greeting: 'Hola',
                      },
                    });
                    checkFormValidity();
                  }}
                >
                  <option value="default" disabled>
                    Elige una de las opciones
                  </option>
                  {admins.map((admin) => (
                    <option key={admin._id} value={admin._id}>
                      {admin.username}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
          <div className="form-row">
            <label className="form-label label--icon">
              Elige el o los motivos para derivar a la mujer con otra
              profesional <small className="required">*</small>
            </label>
            <p className="text-gray800">
              Puedes seleccionar más de una opción si es tu caso
            </p>
            <div className="row-check-2 row">
              {motivosDerivar.map((motivo) => (
                <div className="row-check-2__col col-6" key={motivo.id}>
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="checkbox"
                      name="motivosDerivar"
                      id={motivo.attributes.nombre}
                      value={motivo.attributes.nombre}
                      checked={personalData?.derivada?.motivo?.includes(
                        motivo.attributes.nombre
                      )}
                      onChange={(event) => {
                        const motivos = personalData?.derivada?.motivo || [];
                        if (event.target.checked) {
                          motivos.push(event.target.value);
                          setPersonalData({
                            ...personalData,
                            derivada: {
                              ...personalData.derivada,
                              motivo: motivos,
                            },
                          });
                        } else {
                          const index = motivos.indexOf(event.target.value);
                          if (index !== -1) {
                            motivos.splice(index, 1);
                          }
                          setPersonalData({
                            ...personalData,
                            derivada: {
                              ...personalData.derivada,
                              motivo: motivos,
                            },
                          });
                          checkFormValidity();
                        }
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={motivo.attributes.nombre}
                    >
                      {motivo.attributes.nombre}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="form-row">
            <label className="form-label">
              ¿Cuáles son los motivos adicionales de la derivación?
            </label>
            <textarea
              className="form-control"
              placeholder="Ingresa los motivos adicionales"
              id="motivos"
              value={personalData?.derivada?.detalle || ''}
              onChange={(event) => {
                setPersonalData({
                  ...personalData,
                  derivada: {
                    ...personalData.derivada,
                    detalle: event.target.value,
                  },
                });
                checkFormValidity();
              }}
            ></textarea>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-3">
          <button
            type="submit"
            className="btn btn--type1 btn--370"
            onClick={handleOnConfirm}
            disabled={!isFormValid}
          >
            Derivar perfil a profesional
          </button>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn--type3 btn--370"
            onClick={onHide}
          >
            Cancelar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDerivarComponents;
