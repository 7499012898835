import React, { useEffect, useState, useRef, useCallback } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import { Overlay } from 'react-bootstrap';
import { MessageBoxType } from 'react-chat-elements';
import { Socket } from 'socket.io-client';
import { IWorkshop } from '../../../types/workshopInterface';
import { fetchChatMessages } from '../../../api/chatApi';
import { getSession } from '../../../utils/helpers';
import ChatModalComponent from '../../Chat/chatModal.component';
import { AdminPrincipalRol, IAdmin } from '../../../types/adminInterface';
import Swal from 'sweetalert2';
import { deleteWorkshop } from '../../../api/workshopApi';

interface TalleresComponentProps {
  mode?: 'derivar' | 'seleccionar' | 'ver-mas';
  tallerEstado?: 'proximos' | 'reagendados' | 'concluidos';
  onSelectTaller?: (id: number) => void;
  showAvailableSlotsBadge?: boolean;
  showStockList?: boolean;
  cols?: string;
  workshop: Partial<IWorkshop>;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  socket?: Socket;
  handleCheckboxChange?: (id: string, isSelected: boolean) => void;
  selected?: boolean;
  showChat?: boolean;
  fetchWorkshops?: () => Promise<void>;
}

const TalleresComponent: React.FC<TalleresComponentProps> = ({
  mode = 'derivar',
  showAvailableSlotsBadge = true,
  showStockList = true,
  cols = 'col-md-4',
  tallerEstado = 'proximos',
  workshop,
  setLoading,
  socket,
  handleCheckboxChange,
  selected,
  showChat = true,
  fetchWorkshops,
}) => {
  const [session, setSession] = useState<Partial<IAdmin>>({});
  const [showTooltip, setShowTooltip] = useState<{ [id: number]: boolean }>({});
  const [messages, setMessages] = useState<MessageBoxType[]>([]);
  const [modalShowChat, setModalShowChat] = useState(false);
  const buttonRefs = useRef<Record<number, HTMLElement | null>>({}).current;

  const fetchOldMessages = async (
    roomId: string,
    token: string,
    userId: string
  ) => {
    try {
      if (setLoading) {
        setLoading(true);
      }
      const response = await fetchChatMessages(roomId, token);

      const messages = response.docs.reverse().map((doc) => {
        const message: MessageBoxType = {
          id: doc._id || '',
          position: doc.sender === userId ? 'right' : 'left',
          type: 'text',
          text: doc.message,
          date: new Date(doc.createdAt || ''),
          avatar:
            doc.sender === userId
              ? '/assets/images/icons/user-small.svg'
              : '/assets/images/icons/user-pic-2.svg',
          title:
            doc.sender === userId ? session?.username || 'Tu' : doc.senderName,
          focus: false,
          forwarded: false,
          replyButton: false,
          removeButton: false,
          titleColor: '#000000',
          status: doc.sender === userId ? 'sent' : 'received',
          notch: true,
          retracted: false,
        };
        return message;
      });

      setMessages([...messages]);
      return messages;
    } catch (error) {
      console.error('Error fetching old messages:', error);
    } finally {
      if (setLoading) {
        setLoading(false);
      }
    }
  };

  const setNewMessage = (msg: MessageBoxType) => {
    setMessages((prevMessages) => [...prevMessages, msg]);
    // scroll to the bottom of the chat element with class .rce-mlist
    setTimeout(() => {
      const chatContainer = document.querySelector('.rce-mlist');
      if (chatContainer) {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight + 200,
          behavior: 'smooth',
        });
      }
    }, 300);
  };

  const getBadgeClass = (cupoDisponible: number, cupoTotal: number) => {
    if (cupoDisponible === cupoTotal) {
      return 'badge--blue';
    } else if (cupoDisponible <= cupoTotal * 0.25) {
      return 'badge--danger';
    } else {
      return 'badge--info';
    }
  };

  const getStockList = () => {
    const stockList = [1, 2, 3, 4, 5];
    const takedPlaces = workshop.assignedUsers?.length || 0;
    const workshopSize = workshop.availablePlaces || 0;
    const stock = workshopSize / stockList.length;
    return stockList.map((item, i) => {
      const isTaked = takedPlaces >= item * stock;
      return (
        <li key={i}>
          <span
            className={`icon material-icons-outlined ${
              isTaked ? 'stock--enabled' : 'stock--disabled'
            }`}
          >
            airline_seat_recline_normal
          </span>
        </li>
      );
    });
  };

  const handleTooltipVisibility = (id: number) => {
    setShowTooltip((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const setRef = useCallback((id: number, element: HTMLElement | null) => {
    buttonRefs[id] = element;
  }, []);

  const handleDeleteWorkshop = async (id: string, workshopId: number) => {
    try {
      handleTooltipVisibility(workshopId);
      Swal.fire({
        title: '¿Estás seguro?',
        text: 'Esta acción no se puede deshacer',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
      }).then((resp) => {
        if (resp.isConfirmed) {
          deleteWorkshop(id, session?.token || '');
          Swal.fire({
            title: 'Taller eliminado',
            text: 'El taller ha sido eliminado correctamente',
            icon: 'success',
            confirmButtonText: 'Aceptar',
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then(() => {
            if (fetchWorkshops) {
              fetchWorkshops();
            }
          });
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Hubo un error al eliminar el taller',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  useEffect(() => {
    const session = getSession();
    if (session) {
      setSession(session);
    }
  }, []);

  return (
    <>
      {modalShowChat && socket && (
        <ChatModalComponent
          admin={session}
          roomId={workshop.chatRoomId || ''}
          token={session?.token || ''}
          show={modalShowChat}
          messages={messages}
          onHide={() => setModalShowChat(false)}
          setNewMessage={setNewMessage}
          workshop={workshop}
          socket={socket}
        />
      )}
      <div
        key={workshop.workshopId}
        className={`item-workshop__col ${cols} col-sm-6 col-12 ${tallerEstado}`}
      >
        <div className={`item-workshop ${selected ? 'active' : ''}`}>
          <figure className="item-workshop__image">
            <p
              className={`badge badge-label badge--${
                workshop.workshopType === 'En línea' ? 'success' : 'info'
              }`}
            >
              <span className="icon material-icons-outlined">verified</span>
              <span>
                <small>{(workshop.workshopType || '').toUpperCase()}</small>
              </span>
            </p>
            <img
              src={
                workshop.workshopType === 'En línea'
                  ? '/assets/images/illustrations/workshop.png'
                  : '/assets/images/illustrations/remember.png'
              }
              alt="Taller"
            />
          </figure>
          <article>
            {showAvailableSlotsBadge && (
              <p
                className={`badge badge-label ${getBadgeClass(
                  (workshop.availablePlaces || 0) -
                    (workshop.assignedUsers || []).length,
                  workshop.availablePlaces || 0
                )}`}
              >
                <span className="icon material-icons-outlined">
                  airline_seat_recline_normal
                </span>
                <span>
                  {(workshop.availablePlaces || 0) -
                    (workshop.assignedUsers || []).length}{' '}
                  CUPOS DISPONIBLES
                </span>
              </p>
            )}
            {showStockList && (
              <ul className="stock-list">
                {getStockList()}
                <li>{workshop.availablePlaces || 0} CUPOS</li>
              </ul>
            )}
            <p className="item-workshop__date text-gray600 text-icon mb-0">
              <i className="icon icon--calendar-line"></i>
              {workshop.name} - {workshop.workshopTextDate}
            </p>
            <p className="text-icon">
              <i className="icon icon--clock"></i> {workshop.workshopSchedule}
            </p>
            {mode === 'derivar' && (
              <button type="button" className="btn btn--type1 ">
                Derivar aquí
              </button>
            )}
            {mode === 'ver-mas' &&
              (tallerEstado === 'concluidos' ? (
                <div className="d-flex justify-content-center">
                  <a
                    href={`/talleres-checklist-terminado/${workshop._id}`}
                    className="btn btn--type4 btn--small-100"
                  >
                    Ver listado de asistentes
                  </a>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn--type1 btn--small"
                    ref={(el) => setRef(Number(workshop.workshopId), el)}
                    onClick={() =>
                      handleTooltipVisibility(Number(workshop.workshopId))
                    }
                  >
                    Ver más
                  </button>
                  <Overlay
                    show={!!showTooltip[Number(workshop.workshopId)]}
                    target={buttonRefs[Number(workshop.workshopId)]}
                    placement={window.innerWidth < 768 ? 'bottom' : 'right'}
                    rootClose
                    onHide={() =>
                      setShowTooltip((prev) => ({
                        ...prev,
                        [Number(workshop.workshopId)]: false,
                      }))
                    }
                  >
                    <Tooltip id={`tooltip-${Number(workshop.workshopId)}`}>
                      <ul className="tooltip-options">
                        {[session.principalRol, session.secondaryRol].includes(
                          AdminPrincipalRol.TALLERISTA
                        ) && (
                          <li>
                            <a href={`/crear-taller/${workshop._id}`}>
                              <i className="icon icon--edit"></i>
                              Actualizar taller
                            </a>
                          </li>
                        )}
                        <li>
                          <a href={`/talleres-asistentes/${workshop._id}`}>
                            <i className="icon icon--user"></i>
                            Ver asistentes
                          </a>
                        </li>
                        {showChat && (
                          <li>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                fetchOldMessages(
                                  workshop.chatRoomId || '',
                                  session.token || '',
                                  session._id || ''
                                ).then(() => {
                                  setModalShowChat(true);
                                  if (setLoading) setLoading(false);
                                });
                              }}
                            >
                              <i className="icon icon--whatsapp"></i>
                              Ir al chat
                            </a>
                          </li>
                        )}
                        {(workshop.assignedUsers || []).length === 0 && (
                          <li>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleDeleteWorkshop(
                                  workshop._id || '',
                                  Number(workshop.workshopId)
                                );
                              }}
                            >
                              <i className="icon icon--delete"></i>
                              Eliminar taller
                            </a>
                          </li>
                        )}
                      </ul>
                    </Tooltip>
                  </Overlay>
                </div>
              ))}
            {mode === 'seleccionar' && (
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`taller-${workshop._id}`}
                  name={`taller-${workshop._id}`}
                  checked={selected || false}
                  onChange={(e) =>
                    handleCheckboxChange &&
                    handleCheckboxChange(workshop._id || '', e.target.checked)
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor={`taller-${workshop._id}`}
                >
                  Seleccionar
                </label>
              </div>
            )}
          </article>
        </div>
      </div>
    </>
  );
};

export default TalleresComponent;
