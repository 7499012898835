import React, { useEffect, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IUser, UserSteps } from '../../../../types/userInterface';
import { searchAdmins } from '../../../../api/adminApi';
import { AdminPrincipalRol, IAdmin } from '../../../../types/adminInterface';
import Swal from 'sweetalert2';
import { updateUser } from '../../../../api/usersApi';

interface ModalAsignarComponentProps {
  user: Partial<IUser>;
  session: Partial<IAdmin>;
  setUser: React.Dispatch<React.SetStateAction<Partial<IUser>>>;
  token: string;
  show: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const ModalAsignarComponent: React.FC<ModalAsignarComponentProps> = ({
  user,
  session,
  setUser,
  token,
  show,
  onHide,
  onSuccess,
}) => {
  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('default');
  const [admins, setAdmins] = useState<IAdmin[]>([]);
  const [assignedTo, setAssignedTo] = useState<Partial<IUser>>({});

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
    const selectedAdmin = admins.find(
      (admin) => admin._id === event.target.value
    );
    if (selectedAdmin) {
      setAssignedTo({
        ...user,
        assignedTo: {
          ...(assignedTo.assignedTo || {}),
          adminId: selectedAdmin._id,
        },
      });
    }
  };

  const isButtonDisabled =
    selectedOption === 'default' && !(assignedTo.assignedTo || {})?.greeting;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Evitar recarga de la página al enviar el formulario
    try {
      const result = await updateUser(
        user._id || '',
        { assignedTo: assignedTo.assignedTo, step: UserSteps.ACOMPANAMIENTO },
        token
      );
      if (result) {
        Swal.fire({
          icon: 'success',
          title: 'Asignación exitosa',
          text: 'El usuario ha sido asignado correctamente',
        });
        setUser({ ...result });
        setFormSubmittedSuccessfully(true);
        onSuccess();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error al asignar el usuario',
      });
    }
  };

  const handleModalClose = () => {
    onHide();

    // Si el formulario se envió con éxito, reiniciar el estado de formSubmittedSuccessfully
    if (formSubmittedSuccessfully) {
      setFormSubmittedSuccessfully(false);
    }
  };

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const result = await searchAdmins(
          0,
          100,
          {
            adminId:
              session.principalRol === AdminPrincipalRol.ACOMPANANTE
                ? session.adminId
                : undefined,
            colectiva: { name: session?.colectiva?.name },
            principalRol: AdminPrincipalRol.ACOMPANANTE,
            secondaryRol: AdminPrincipalRol.ACOMPANANTE,
          },
          token
        );
        if (result && result.docs.length) {
          setAdmins(result.docs);
        }
      } catch (error) {
        console.error(
          'Error al obtener los datos de los administradores:',
          error
        );
      }
    };
    fetchAdmins();
  }, [show]);

  useEffect(() => {
    setAssignedTo({ ...user });
  }, [user]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal--gray"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="text-center">
              <i className="icon icon--user-black"></i> Asignar acompañante
            </h2>
          </Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={handleModalClose}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <h3 className="h4 text-center mb-5">
            Selecciona una acompañante de la Colectiva
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="form-row pb-3">
              <label className="form-label label--icon">
                Acompañante <small className="required">*</small>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Acompañantes disponibles</Tooltip>}
                >
                  <span className="material-icons-outlined icon">
                    help_outline
                  </span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select requiredField"
                aria-label="Acompañante"
                defaultValue={'default'}
                name="acompanante"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="default" disabled>
                  Selecciona una opción
                </option>
                {admins.map((acompanante) => (
                  <option key={acompanante._id} value={acompanante._id}>
                    {acompanante.username}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-row pb-3">
              <label className="form-label label--icon">
                Saludo <small className="required">*</small>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Saludo</Tooltip>}
                >
                  <span className="material-icons-outlined icon">
                    help_outline
                  </span>
                </OverlayTrigger>
              </label>
              <input
                type="text"
                className="form-control requiredField"
                aria-label="Saludo"
                name="greeting"
                placeholder="Envía un saludo a la persona"
                value={assignedTo.assignedTo?.greeting || ''}
                onChange={(event) => {
                  setAssignedTo({
                    ...assignedTo,
                    assignedTo: {
                      ...assignedTo.assignedTo,
                      greeting: event.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="form-row d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn--type1 btn--290"
                disabled={isButtonDisabled}
              >
                Asignar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalAsignarComponent;
