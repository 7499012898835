import { IUser } from './userInterface';

export interface StrapiDoc {
  id?: number;
  name?: string;
}

export enum AdminPrincipalRol {
  ACOMPANANTE = 'Acompañante',
  ADMIN = 'Admin',
  ENTREGADORA = 'Entregadora',
  LIDER_COLECTIVA = 'Líder Colectiva',
  TALLERISTA = 'Tallerista',
}

export enum AdminStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DELETED = 'deleted',
}

export interface IAdminTag {
  tag: string;
  adminId: string;
  createdAt?: string;
}

export interface IAdmin {
  _id: string;
  adminId: number;
  username: string;
  lastName: string;
  email: string;
  phone: string;
  password?: string;
  invCode?: number;
  resetPass?: number;
  expires?: number;
  birthday?: string;
  age?: number;
  country?: StrapiDoc;
  state?: StrapiDoc;
  city?: StrapiDoc;
  colectiva?: StrapiDoc;
  address?: string;
  postalCode?: string;
  principalJob?: string;
  educationLevel?: string;
  principalRol: string;
  secondaryRol: string;
  createdAt?: Date;
  isActive?: boolean;
  status?: AdminStatus;
  tags?: IAdminTag[];
  notes?: IAdminTag[];
  specialistIn?: IAdminTag[];
  token?: string;
  ids?: string[];
}

interface IPaginate {
  totalDocs?: number;
  offset: number;
  limit: number;
  totalPages?: number;
  page?: number;
  pagingCounter?: number;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
  prevPage?: number;
  nextPage?: number;
}

export interface IAdminPaginate extends IPaginate {
  docs: IAdmin[];
}

export interface IUserChat {
  _id?: string;
  roomId: string;
  sender: string;
  message: string;
  transactionId: number;
  senderName: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IUserChatPaginate extends IPaginate {
  docs: IUserChat[];
}
export interface IChatRoom {
  _id?: string;
  roomId?: string;
  userIdA: string;
  userIdB: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IAdminEvaluationTopic {
  topic: string;
  score: number;
}

export interface IAdminEvaluation {
  _id: string;
  evaluationId: number;
  adminId: string;
  userId: IUser;
  adminEvaluationTopics: IAdminEvaluationTopic[];
  observations: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IAdminEvaluationPaginate extends IPaginate {
  docs: IAdminEvaluation[];
}
