import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AdminPrincipalRol, IAdmin } from '../../../types/adminInterface';
import LoaderComponent from '../../Loader/loader.component';
import { fetchStrapiDocs } from '../../../api/strapi';
import { strapiDoc } from '../../../types/strapiDocs';
import { getSession } from '../../../utils/helpers';
interface AmigaInfoComponentProps {
  admin: IAdmin;
}

const AmigaInfoComponent: React.FC<AmigaInfoComponentProps> = ({
  admin: prevAdmin,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState<Partial<IAdmin>>({});
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const [formularioValido, setFormularioValido] = useState(false);
  const [countryList, setCountryList] = useState<strapiDoc[]>([]);
  const [stateList, setStateList] = useState<strapiDoc[]>([]);

  const calculateAge = (birthday: string) => {
    const birthDate = new Date(birthday);
    const difference = Date.now() - birthDate.getTime();
    const ageDate = new Date(difference);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const fetchStates = async (countryId: number) => {
    // Fetch states
    const query = {
      sort: ['nombre:asc'],
      filters: {
        pais: { $eq: countryId },
      },
      pagination: { pageSize: 100 },
      populate: '*',
    };
    const states = await fetchStrapiDocs('/estados', query);
    setStateList(states.data);
  };

  const onFieldChange = (
    name: keyof IAdmin,
    value: string | { id: number; name: string }
  ) => {
    if (name === 'birthday') {
      setAdmin({
        ...admin,
        [name as string]: value,
        age: calculateAge(value as string),
      });
      return;
    }
    setAdmin({ ...admin, [name]: value });
  };

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll(
      '.requiredField'
    ) as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;

    camposRequeridos.forEach((campo) => {
      if (
        campo.value === undefined ||
        campo.value === null ||
        campo.value === '' ||
        campo.value === 'default'
      ) {
        todosCompletos = false;
      } else {
        const campoCompleto = campo.value.trim() !== '';
        todosCompletos = todosCompletos && campoCompleto;
      }
    });
    setFormularioValido(todosCompletos);
  };

  const handleSubmit = () => {
    const stateAdmin = { ...admin, ...prevAdmin };
    if (
      [session.principalRol, session.secondaryRol].includes(
        AdminPrincipalRol.LIDER_COLECTIVA
      )
    ) {
      return navigate('/nueva-amiga-acceso', {
        state: {
          admin: {
            ...stateAdmin,
            colectiva: {
              id: session.colectiva?.id,
              name: session.colectiva?.name,
            },
          },
        },
      });
    }
    navigate('/nueva-amiga-colectiva', { state: { admin: stateAdmin } });
  };

  useEffect(() => {
    verifyFields();
  }, [admin]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const session = getSession();
        if (!session) {
          navigate('/login');
          return;
        }
        setSession(session);
        // Fetch countries
        const query = { sort: ['nombre:asc'] };
        const countries = await fetchStrapiDocs('/paises', query);
        const data = countries.data;
        data.push({ id: 0, attributes: { nombre: 'Otro' } });
        setCountryList(countries.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        if (prevAdmin && prevAdmin.username) {
          setAdmin(prevAdmin);
          if (prevAdmin.birthday) {
            setAdmin({
              ...prevAdmin,
              age: calculateAge(prevAdmin.birthday),
            });
          }
          if (prevAdmin.country?.id) {
            fetchStates(prevAdmin.country.id || 0);
          }
        }
      }
    };
    fetchData();
  }, []);

  return (
    <div className="form-block">
      {loading && <LoaderComponent />}
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label htmlFor="name" className="form-label label--icon">
            Nombre o seudónimo <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Nombre del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control requiredField"
            id="name"
            placeholder="Escribe el nombre"
            value={admin.username || ''}
            onChange={(e) => {
              onFieldChange('username', e.target.value);
            }}
          />
        </div>
        <div className="form-row">
          <label htmlFor="nacionalidad" className="form-label label--icon">
            Nacionalidad <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Nacionalidad del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <select
            id="nacionalidad"
            className="form-select requiredField"
            aria-label="Tu nacionalidad"
            value={admin.country?.id || 'default'}
            onChange={(e) => {
              const countryId = Number(e.target.value);
              const foundedCountry = countryList.find(
                ({ id }) => id === countryId
              );
              if (!foundedCountry) return;
              const value = {
                id: foundedCountry.id,
                name: foundedCountry.attributes.nombre,
              };
              onFieldChange('country', value);
              fetchStates(countryId);
            }}
          >
            <option value="default" disabled>
              Elige una de las opciones
            </option>
            {countryList.map((country) => (
              <option key={country.id} value={country.id}>
                {country.attributes.nombre}
              </option>
            ))}
          </select>
        </div>
        {stateList.length > 0 && (
          <div className="form-row">
            <label htmlFor="region" className="form-label label--icon">
              Región <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Región del usuario.</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <select
              id="region"
              className="form-select requiredField"
              aria-label="Tu región"
              value={admin.state?.id || 'default'}
              onChange={(e) => {
                const stateId = Number(e.target.value);
                const foundedState = stateList.find(({ id }) => id === stateId);
                if (!foundedState) return;
                const value = {
                  id: foundedState.id,
                  name: foundedState.attributes.nombre,
                };
                onFieldChange('state', value);
              }}
            >
              <option value="default" disabled>
                Elige una de las opciones
              </option>
              {stateList.map((state, index) => (
                <option key={index} value={state.id}>
                  {state.attributes.nombre}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="form-row form--last">
          <button
            type="submit"
            className="btn btn--type1"
            disabled={!formularioValido}
          >
            Siguiente
          </button>
        </div>
        <div className="form-row form--last">
          <a href="/inicio-administrador" className="btn btn--type2">
            Cancelar
          </a>
        </div>
      </form>
    </div>
  );
};

export default AmigaInfoComponent;
