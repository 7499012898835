import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import AcompananteDataComponent from '../../../../components/Forms/adminDetail/acompananteDetail.component';
import LoaderComponent from '../../../../components/Loader/loader.component';
import { IAdmin } from '../../../../types/adminInterface';
import { useNavigate, useParams } from 'react-router-dom';
import { getSession } from '../../../../utils/helpers';
import { getAdmin, updateAdmin } from '../../../../api/adminApi';

interface EditAdminScreenProps {
  // Define your component props here
}

const EditAdminScreen: React.FC<EditAdminScreenProps> = () => {
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState<Partial<IAdmin>>({});
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});

  const navigate = useNavigate();

  const { id: adminId } = useParams<{ id: string }>();

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchAdmin = async (id: string) => {
    try {
      setLoading(true);
      const session = getSession();
      if (!session || !session.token) {
        navigate('/login');
        return;
      }
      setSession(session);
      const response = await getAdmin(id, session.token);
      setAdmin(response);
    } catch (error) {
      setError('Ocurrió un error al obtener los datos');
    } finally {
      setLoading(false);
    }
  };

  const saveAdmin = async (admin: Partial<IAdmin>, adminId: string) => {
    try {
      setLoading(true);
      const response = await updateAdmin(adminId, admin, session.token || '');
      setAdmin(response);
      Swal.fire({
        icon: 'success',
        title: 'Datos actualizados correctamente',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(() => {
        navigate(-1);
      });
    } catch (error) {
      setError('Ocurrió un error al actualizar los datos');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (adminId) {
      fetchAdmin(adminId);
    }
  }, [adminId]);

  return (
    <>
      <HeaderShared />
      {loading && <LoaderComponent />}
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="auth-row row">
              <div className="col-12 mb-4">
                <a href="#" className="btn--back" onClick={() => navigate(-1)}>
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-md-5 col-12">
                <h1 className="mb-5">Editar información</h1>
                <h2 className="h3">Información personal</h2>
                {admin && admin._id && session && (
                  <AcompananteDataComponent
                    admin={admin}
                    saveAdmin={saveAdmin}
                  />
                )}
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-280">
                  <img
                    src="/assets/images/illustrations/about.png"
                    alt="Women"
                  />
                </figure>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default EditAdminScreen;
