import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { MessageBoxType } from 'react-chat-elements';
import { io, Socket } from 'socket.io-client';
import { format } from 'date-fns';
import { AdminPrincipalRol, IAdmin } from '../../../types/adminInterface';
import TagsComponent from '../../Forms/tags/tags.component';
import ActionsColectivaButtonComponent from '../Buttons/actionsColectiva.component';
import { TAGS_DATA } from '../../../utils/tags';
import {
  fetchChatMessages,
  getChatRoom,
  newChatRoom,
} from '../../../api/chatApi';
import ChatModalComponent from '../../Chat/chatModal.component';
import TagsManagerComponent from '../../Forms/tagsManager/tagsManager.component';
import DonationsBlockComponent from '../Donations/donationsBlock.component';
import NotesComponent from '../Notes/notes.component';

interface AdminDataComponentProps {
  session: Partial<IAdmin>;
  admin: Partial<IAdmin>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: (message: string) => void;
  saveAdmin: (admin: Partial<IAdmin>, adminId: string) => Promise<void>;
}
const AdminDataComponent: React.FC<AdminDataComponentProps> = ({
  session,
  admin,
  setLoading,
  setError,
  saveAdmin,
}) => {
  const [socket, setSocket] = useState<Socket>();
  const [messages, setMessages] = useState<MessageBoxType[]>([]);
  const [modalShowChat, setModalShowChat] = useState(false);
  const [currentChatRoomId, setCurrentChatRoomId] = useState<string>('');
  const [tagsShow, setTagsShow] = useState<string[]>([]);

  const getSocket = async () => {
    const socket = io('https://chat.conlasamigas.org/');
    socket.on('connect', () => {
      console.log('Connected to server');
    });
    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });
    setSocket(socket);
  };

  const fetchOldMessages = async (
    roomId: string,
    token: string,
    userId: string
  ) => {
    try {
      if (setLoading) {
        setLoading(true);
      }
      const response = await fetchChatMessages(roomId, token);

      const messages = response.docs.reverse().map((doc) => {
        const message: MessageBoxType = {
          id: doc._id || '',
          position: doc.sender === userId ? 'right' : 'left',
          type: 'text',
          text: doc.message,
          date: new Date(doc.createdAt || ''),
          avatar:
            doc.sender === userId
              ? '/assets/images/icons/user-small.svg'
              : '/assets/images/icons/user-pic-2.svg',
          title:
            doc.sender === userId ? session?.username || 'Tu' : doc.senderName,
          focus: false,
          forwarded: false,
          replyButton: false,
          removeButton: false,
          titleColor: '#000000',
          status: doc.sender === userId ? 'sent' : 'received',
          notch: true,
          retracted: false,
        };
        return message;
      });

      setMessages([...messages]);
      return messages;
    } catch (error) {
      console.error('Error fetching old messages:', error);
    } finally {
      if (setLoading) {
        setLoading(false);
      }
    }
  };

  const findAndFetchChat = async (adminId: string) => {
    try {
      setLoading(true);
      const foundedChatRoom = await getChatRoom(
        adminId,
        session._id || '',
        session.token || ''
      );
      if (foundedChatRoom && foundedChatRoom.roomId) {
        await fetchOldMessages(
          foundedChatRoom.roomId,
          session.token || '',
          session._id || ''
        );
        setModalShowChat(true);
        setCurrentChatRoomId(foundedChatRoom.roomId);
      } else {
        const chatRoom = await newChatRoom(
          { userIdA: adminId, userIdB: session._id || '' },
          session.token || ''
        );
        if (chatRoom && chatRoom.roomId) {
          setMessages([]);
          setCurrentChatRoomId(chatRoom.roomId);
          setModalShowChat(true);
        }
      }
    } catch (error) {
      setError('Ocurrió un error al obtener el chat');
    } finally {
      setLoading(false);
    }
  };

  const setNewMessage = (msg: MessageBoxType) => {
    setMessages((prevMessages) => [...prevMessages, msg]);
    // scroll to the bottom of the chat element with class .rce-mlist
    setTimeout(() => {
      const chatContainer = document.querySelector('.rce-mlist');
      if (chatContainer) {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight + 200,
          behavior: 'smooth',
        });
      }
    }, 300);
  };

  useEffect(() => {
    switch (admin.status) {
      case 'active':
        setTagsShow(['tag-10']);
        break;
      case 'inactive':
        setTagsShow(['tag-11']);
        break;
      case 'deleted':
        setTagsShow(['tag-12']);
        break;
      default:
        setTagsShow([]);
        break;
    }
  }, [admin.status]);

  useEffect(() => {
    getSocket();
  }, []);

  return (
    <>
      <div className="row view-head-row">
        {modalShowChat && socket && (
          <ChatModalComponent
            admin={session}
            toAdmin={admin}
            roomId={currentChatRoomId}
            token={session?.token || ''}
            show={modalShowChat}
            messages={messages}
            onHide={() => setModalShowChat(false)}
            setNewMessage={setNewMessage}
            socket={socket}
          />
        )}
        <div className="view-detail col-md-8 col-12">
          <h1 className="text-40">{admin.username || ''}</h1>
          <p className="text-10">{`#${admin.adminId}`}</p>
          <p className="text-20">{admin.principalRol || ''}</p>
          <div className="view-detail__tags">
            <TagsComponent
              tags={TAGS_DATA.filter((tag) => tagsShow.includes(tag.id))}
            />
          </div>
          {/* <div className="col-12 mb-5">
          <p className="text-gray600 mb-0">
            <strong>Mi tiempo de respuesta</strong>
          </p>
          <p className="text-icon">
            <i className="icon icon--clock"></i> menos de 10 minutos
          </p>
        </div> */}
        </div>
        <aside className="view-detail__action no-flex  col-md-4 col-12">
          <div className="view-detail__action-col col-12 col-reset mb-3">
            <a
              href=""
              className="btn btn--chat"
              onClick={(e) => {
                e.preventDefault();
                findAndFetchChat(admin._id || '');
              }}
            >
              Ver Chat <i className="icon icon--chat"></i>
            </a>
            <ActionsColectivaButtonComponent
              admin={admin}
              saveAdmin={saveAdmin}
            />
          </div>
        </aside>
      </div>
      <div className="row mb-4">
        <div
          className={`${
            admin.principalRol === AdminPrincipalRol.LIDER_COLECTIVA
              ? 'col-lg-6'
              : 'col-lg-9'
          } col-12 mb-4`}
        >
          <table className="table-user-data">
            <tbody>
              <tr>
                <td>
                  <p className="data-info">
                    <i className="icon icon--message-clip"></i> Registrada:
                  </p>
                </td>
                <td>{`${
                  admin.createdAt
                    ? format(new Date(admin.createdAt || ''), 'dd/MM/yy')
                    : ''
                }`}</td>
              </tr>
              <tr>
                <td>
                  <p className="data-info">
                    <i className="icon icon--pin"></i> Colectiva:
                  </p>
                </td>
                <td>{`${admin.colectiva?.name || ''}`}</td>
              </tr>
              <tr>
                <td>
                  <p className="data-info">
                    <i className="icon icon--edit-clip"></i>Rol Principal:
                  </p>
                </td>
                <td>{admin.principalRol}</td>
              </tr>
              <tr>
                <td>
                  <p className="data-info">
                    <i className="icon icon--edit-clip"></i> Rol Secundario:
                  </p>
                </td>
                <td>
                  <span className="text-gray600">{`${
                    admin.secondaryRol || 'Sin Asignar'
                  }`}</span>
                </td>
              </tr>
            </tbody>
          </table>
          {[admin.principalRol, admin.secondaryRol].includes(
            AdminPrincipalRol.TALLERISTA
          ) && (
            <div className="col-12 col-reset">
              <h4 className="mb-3">Especialista en</h4>
              <TagsManagerComponent
                admin={session}
                user={{
                  tags: admin.specialistIn || [],
                }}
                handleAddition={(tags) => {
                  saveAdmin(
                    {
                      specialistIn: tags,
                    },
                    admin._id || ''
                  );
                }}
              />
            </div>
          )}
          <div className="col-12 mt-5">
            <Accordion defaultActiveKey="0" className="accordion-regular">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Etiquetas</Accordion.Header>
                <Accordion.Body className="pl-0 pr-0">
                  <TagsManagerComponent
                    admin={session}
                    user={{
                      tags: admin.tags || [],
                    }}
                    handleAddition={(tags) => {
                      saveAdmin(
                        {
                          tags: tags,
                        },
                        admin._id || ''
                      );
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Notas</Accordion.Header>
                <Accordion.Body>
                  <NotesComponent
                    admin={session}
                    user={{
                      notes: (admin.notes || []).map((note) => ({
                        note: note.tag,
                        adminId: note.adminId,
                        createdAt: note.createdAt || '',
                      })),
                    }}
                    handleAddNote={(note) => {
                      saveAdmin(
                        {
                          notes: [
                            ...(admin.notes || []),
                            {
                              tag: note.note,
                              adminId: note.adminId as string,
                              createdAt: new Date().toISOString(),
                            },
                          ],
                        },
                        admin._id || ''
                      );
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Información personal</Accordion.Header>
                <Accordion.Body>
                  <div className="form-block">
                    <form>
                      <div className="form-row">
                        <label
                          htmlFor="name"
                          className="form-label label--icon"
                        >
                          Nombre o seudónimo{' '}
                          <small className="required">*</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Escribe el nombre"
                          value={admin?.username || ''}
                          readOnly={true}
                        />
                      </div>
                      <div className="form-row">
                        <label
                          htmlFor="email"
                          className="form-label label--icon"
                        >
                          Correo electrónico
                          <small className="required">*</small>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Escribe el correo electrónico"
                          value={admin?.email || ''}
                          readOnly={true}
                        />
                      </div>
                      <div className="form-row">
                        <label
                          htmlFor="phone"
                          className="form-label label--icon"
                        >
                          Teléfono
                          <small className="required">*</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          placeholder="Escribe el teléfono"
                          value={admin?.phone || ''}
                          readOnly={true}
                        />
                      </div>
                      <div className="form-row">
                        <label
                          htmlFor="state"
                          className="form-label label--icon"
                        >
                          Región
                          <small className="required">*</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="state"
                          placeholder="Escribe el estado"
                          value={admin?.state?.name || ''}
                          readOnly={true}
                        />
                      </div>
                    </form>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        {admin.principalRol === AdminPrincipalRol.LIDER_COLECTIVA && (
          <aside className="col-lg-5 offset-lg-1 col-12">
            <DonationsBlockComponent
              quantity={10100}
              donations={3400}
              increment={80.1}
              button={true}
            />
          </aside>
        )}
      </div>
    </>
  );
};

export default AdminDataComponent;
