import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { format, getUnixTime } from 'date-fns';
import ProcessStatusComponent from '../../ProcessStatus/processStatus.component';
import {
  IUser,
  UserDeliverStatus,
  UserSteps,
} from '../../../../types/userInterface';
import { IAdmin } from '../../../../types/adminInterface';
import { getSession } from '../../../../utils/helpers';

interface ModalProcessProps {
  user: Partial<IUser>;
  show: boolean;
  onHide: () => void;
  onShowTaller: () => void;
  setModalShowChat: (value: React.SetStateAction<boolean>) => void;
  setModalShowAsignar: (show: boolean) => void;
}

interface UserProgress {
  key: keyof IUser;
  description: string;
  createdAt: string;
  updatedAt: string;
  unixTimestamp: number;
}

const ModalProcessComponents: React.FC<ModalProcessProps> = ({
  user,
  show,
  onHide,
  onShowTaller,
  setModalShowChat,
  setModalShowAsignar,
}) => {
  const steps = [
    {
      title: 'Cuéntanos de ti',
      description: 'Recibimos tu información',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description:
        (user?.step || 0) >= UserSteps.ACOMPANAMIENTO
          ? 'Terminado'
          : 'En proceso',
      status:
        (user?.step || 0) >= UserSteps.ACOMPANAMIENTO ? 'status--success' : '',
      completed: (user?.step || 0) >= UserSteps.ACOMPANAMIENTO,
      color:
        (user?.step || 0) >= UserSteps.ACOMPANAMIENTO ? 'green300' : 'gray600',
    },
    {
      title: 'Te invitamos al taller',
      description:
        (user?.step || 0) >= UserSteps.TALLER ? 'Terminado' : 'En proceso',
      status: (user?.step || 0) >= UserSteps.TALLER ? 'status--success' : '',
      completed: (user?.step || 0) >= UserSteps.TALLER,
      color: (user?.step || 0) >= UserSteps.TALLER ? 'green300' : 'gray600',
    },
    {
      title: 'En entrega',
      description:
        (user?.step || 0) >= UserSteps.ENTREGA ? 'En proceso' : 'Pendiente',
      status: (user?.step || 0) >= UserSteps.ENTREGA ? 'status--success' : '',
      completed: (user?.step || 0) >= UserSteps.ENTREGA,
      color: (user?.step || 0) >= UserSteps.ENTREGA ? 'green300' : 'gray600',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },

    // Status color
    //-- In progress status--process - purple500
    //-- In progress status--success - green300
    //-- In progress status neutral '' - gray600
  ];
  const [session, setSession] = useState<Partial<IAdmin>>({});
  const [userProgress, setUserProgress] = useState<UserProgress[]>([]);
  const [groupedProgress, setGroupedProgress] = useState<{
    [key: string]: UserProgress[];
  }>({});

  const splitUserProgressByDay = (userProgress: UserProgress[]) => {
    const groupedProgress: { [key: string]: UserProgress[] } = {};
    userProgress.forEach((progress) => {
      const date = new Date(progress.createdAt);
      // just get the day, no hours, minutes, seconds, etc.
      const day = date.toLocaleDateString();
      if (!groupedProgress[day]) {
        groupedProgress[day] = [];
      }
      groupedProgress[day].push(progress);
    });

    console.log({ groupedProgress });
    setGroupedProgress(groupedProgress);
    return groupedProgress;
  };

  useEffect(() => {
    const userKeys: UserProgress[] = [
      {
        key: 'createdAt',
        description: 'Comienza el proceso',
        createdAt: user.createdAt || '',
        updatedAt: user.updatedAt || '',
        unixTimestamp: getUnixTime(new Date(user.createdAt || '')),
      },
      {
        key: 'datosCausales',
        description: 'Se registraron las causales',
        createdAt: user.datosCausales?.createdAt || '',
        updatedAt: user.datosCausales?.updatedAt || '',
        unixTimestamp: getUnixTime(
          new Date(user.datosCausales?.createdAt || '')
        ),
      },
      {
        key: 'datosSociodemograficos',
        description: 'Se registraron los datos sociodemográficos',
        createdAt: user.datosSociodemograficos?.createdAt || '',
        updatedAt: user.datosSociodemograficos?.updatedAt || '',
        unixTimestamp: getUnixTime(
          new Date(user.datosSociodemograficos?.createdAt || '')
        ),
      },
      {
        key: 'contextoSociocultural',
        description: 'Se registraron los datos socioculturales',
        createdAt: user.contextoSociocultural?.createdAt || '',
        updatedAt: user.contextoSociocultural?.updatedAt || '',
        unixTimestamp: getUnixTime(
          new Date(user.contextoSociocultural?.createdAt || '')
        ),
      },
      {
        key: 'saludGinecologica',
        description: 'Se registraron los datos de salud ginecológica',
        createdAt: user.saludGinecologica?.createdAt || '',
        updatedAt: user.saludGinecologica?.updatedAt || '',
        unixTimestamp: getUnixTime(
          new Date(user.saludGinecologica?.createdAt || '')
        ),
      },
      {
        key: 'saludYEmociones',
        description: 'Se registraron los datos de salud y emociones',
        createdAt: user.saludYEmociones?.createdAt || '',
        updatedAt: user.saludYEmociones?.updatedAt || '',
        unixTimestamp: getUnixTime(
          new Date(user.saludYEmociones?.createdAt || '')
        ),
      },
      {
        key: 'sexualidadYEmociones',
        description: 'Se registraron los datos de sexualidad y emociones',
        createdAt: user.sexualidadYEmociones?.createdAt || '',
        updatedAt: user.sexualidadYEmociones?.updatedAt || '',
        unixTimestamp: getUnixTime(
          new Date(user.sexualidadYEmociones?.createdAt || '')
        ),
      },
      {
        key: 'violentometro',
        description: 'Se registraron los datos de violencia',
        createdAt: user.violentometro?.createdAt || '',
        updatedAt: user.violentometro?.updatedAt || '',
        unixTimestamp: getUnixTime(
          new Date(user.violentometro?.createdAt || '')
        ),
      },
      {
        key: 'incidenciaComunicaciones',
        description: 'Se registraron los datos de incidencia de comunicaciones',
        createdAt: user.incidenciaComunicaciones?.createdAt || '',
        updatedAt: user.incidenciaComunicaciones?.updatedAt || '',
        unixTimestamp: getUnixTime(
          new Date(user.incidenciaComunicaciones?.createdAt || '')
        ),
      },
      {
        key: 'assignedTo',
        description: 'Se asignó un acompañante',
        createdAt: user.assignedTo?.createdAt || '',
        updatedAt: user.assignedTo?.updatedAt || '',
        unixTimestamp: getUnixTime(new Date(user.assignedTo?.createdAt || '')),
      },
      {
        key: 'colectiva',
        description: 'Se asignó a una colectiva',
        createdAt: user.colectiva?.createdAt || '',
        updatedAt: user.colectiva?.updatedAt || '',
        unixTimestamp: getUnixTime(new Date(user.colectiva?.createdAt || '')),
      },
      {
        key: 'derivada',
        description: 'Se derivó a otra institución',
        createdAt: user.derivada?.createdAt || '',
        updatedAt: user.derivada?.createdAt || '',
        unixTimestamp: getUnixTime(new Date(user.derivada?.createdAt || '')),
      },
      {
        key: 'workshopSelected',
        description: 'Se registró el taller seleccionado',
        createdAt: user.workshopSelected?.createdAt || '',
        updatedAt: user.workshopSelected?.createdAt || '',
        unixTimestamp: getUnixTime(
          new Date(user.workshopSelected?.createdAt || '')
        ),
      },
      {
        key: 'assignedDeliverer',
        description: 'Se registró a la entregadora',
        createdAt: user.assignedDeliverer?.createdAt || '',
        updatedAt: user.assignedDeliverer?.updatedAt || '',
        unixTimestamp: getUnixTime(
          new Date(user.assignedDeliverer?.createdAt || '')
        ),
      },
      {
        key: 'assignedDeliverer',
        description: 'Se reallizó la entrega',
        createdAt:
          user.assignedDeliverer?.status === UserDeliverStatus.DELIVERED
            ? user.assignedDeliverer?.updatedAt || ''
            : '',
        updatedAt:
          user.assignedDeliverer?.status === UserDeliverStatus.DELIVERED
            ? user.assignedDeliverer?.updatedAt || ''
            : '',
        unixTimestamp: getUnixTime(
          new Date(user.assignedDeliverer?.updatedAt || '')
        ),
      },
    ];

    const sortResult = userKeys
      .filter((r) => r.createdAt !== '')
      .sort((a, b) => {
        const unixTimestampA = a.unixTimestamp;
        const unixTimestampB = b.unixTimestamp;
        return unixTimestampA - unixTimestampB;
      });
    setUserProgress(sortResult);
    splitUserProgressByDay(sortResult);
  }, [user]);

  useEffect(() => {
    const session = getSession();
    if (session) {
      setSession(session);
    }
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="col-12">
          <h2 className="text-40 text-center text-500 pb-2">
            Historial de {`${user.username || ''}`}
          </h2>
          <ProcessStatusComponent steps={steps} currentStepIndex={0} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3 className="h4 text-regular">Detalle del progreso</h3>
        {user.step === UserSteps.MI_PROCESO && (
          <div>
            <h4 className="h6 text-regular text-pink500 mb-3">Hoy</h4>
            <div className="card-process__row">
              <div className="card-process__left">
                <p className="icon-text">
                  <i className="icon icon--document"></i>
                  Es momento de asignar acompañamiento
                </p>
              </div>
              <div className="card-process__right">
                <button
                  type="button"
                  className="btn btn--type1"
                  onClick={() => {
                    setModalShowAsignar(true);
                    onHide();
                  }}
                >
                  Asignar acompañamiento
                </button>
              </div>
            </div>
          </div>
        )}
        {user.step === UserSteps.ACOMPANAMIENTO && (
          <div>
            <h4 className="h6 text-regular text-pink500 mb-3">Hoy</h4>
            <div className="card-process__row">
              <div className="card-process__left">
                <p className="icon-text">
                  <i className="icon icon--document"></i>
                  Es momento de asignarle un taller
                </p>
              </div>
              <div className="card-process__right">
                <button
                  type="button"
                  className="btn btn--type1"
                  onClick={onShowTaller}
                >
                  Seleccionar taller
                </button>
              </div>
            </div>
          </div>
        )}
        {userProgress.length > 0 &&
          Object.keys(groupedProgress)
            .reverse()
            .map((group, index) => (
              <div key={index}>
                <h4 className="h6 text-regular text-pink500 mb-3">{group}</h4>
                {groupedProgress[group].map((item, index) => (
                  <div key={index} className="card-process__row">
                    <div className="card-process__left">
                      <p className="icon-text">
                        <i className="icon icon--document"></i>
                        {item.description}
                      </p>
                    </div>
                    <div className="card-process__right">
                      <p className="icon-text">
                        <i className="icon icon--clock"></i>
                        {format(
                          new Date(item.createdAt || ''),
                          'MM/dd/yyyy h:mm a'
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
      </Modal.Body>
      <Modal.Footer>
        {(user.step || 0) > UserSteps.MI_PROCESO &&
          (user.assignedTo?.adminId as IAdmin)._id === session._id && (
            <>
              <div className="col-12 text-center">
                <p className="text-20">
                  <strong>
                    Ponte en contacto con la mujer para conocerla y coordinar
                    juntas el acompañamiento
                  </strong>
                </p>
              </div>
              <div className="col-12 d-flex justify-content-center mb-3">
                <button
                  type="button"
                  className="btn btn--type1 btn--370"
                  onClick={(e) => {
                    e.preventDefault();
                    onHide();
                    setModalShowChat(true);
                  }}
                >
                  Contactar
                </button>
              </div>
            </>
          )}
        <div className="col-12 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn--type3 btn--370"
            onClick={onHide}
          >
            Cerrar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalProcessComponents;
