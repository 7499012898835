import React, { useEffect, useState } from 'react';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import GlobalScoreComponent from '../../../../components/Elements/Quality/globalScore.component';
import StarScore from '../../../../components/Elements/Quality/starScore.component';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  IAdmin,
  IAdminEvaluation,
  IAdminEvaluationTopic,
} from '../../../../types/adminInterface';
import { getAdmin, getAdminEvaluations } from '../../../../api/adminApi';
import { getSession } from '../../../../utils/helpers';
import LoaderComponent from '../../../../components/Loader/loader.component';
import { format } from 'date-fns';

const EvaluationAdminScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const [evaluations, setEvaluations] = useState<IAdminEvaluation[]>([]);

  const { id: adminId } = useParams();
  const navigate = useNavigate();

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchEvaluations = async () => {
    try {
      setLoading(true);
      const session = getSession();
      if (!session || !adminId) {
        navigate('/login');
        return;
      }
      const response = await getAdminEvaluations(
        {
          page: 1,
          limit: 50,
          adminId,
        },
        session.token || ''
      );
      const admin = await getAdmin(adminId, session.token || '');

      if (response && admin) {
        setEvaluations(response.docs);
        setAdmin(admin);
      }
    } catch (error) {
      setError('Error al obtener las evaluaciones');
    } finally {
      setLoading(false);
    }
  };

  const getTopicsAverage = (evaluations: IAdminEvaluation[]) => {
    const adminEvaluationTopics: IAdminEvaluationTopic[] = [];
    evaluations.forEach((evaluation) => {
      evaluation.adminEvaluationTopics.forEach((topic) => {
        adminEvaluationTopics.push(topic);
      });
    });

    const differentTopics = Array.from(
      new Set(adminEvaluationTopics.map(({ topic }) => topic))
    );
    const topicsAverage = differentTopics.map((topic) => {
      const scores = adminEvaluationTopics
        .filter((evaluationTopic) => evaluationTopic.topic === topic)
        .map(({ score }) => score);
      const average = scores.reduce((a, b) => a + b, 0) / scores.length;
      return {
        topic,
        average: Number(average.toFixed(2)),
      };
    });

    return topicsAverage.map(({ topic, average }, index) => {
      return (
        <div className="col-md-4 col-12" key={index}>
          <GlobalScoreComponent type={topic} score={average} />
        </div>
      );
    });
  };

  useEffect(() => {
    fetchEvaluations();
  }, []);

  return (
    <>
      <HeaderShared />
      {loading && <LoaderComponent />}
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="auth-row row">
              <div className="col-12 mb-4">
                <a
                  href="#"
                  className="btn--back"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-12 mb-4">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <h1>{admin.username || ''}</h1>
                    <p className="text-20">{admin.principalRol || ''}</p>
                  </div>
                  <aside className="col-md-6 col-12">
                    <div className="row">{getTopicsAverage(evaluations)}</div>
                  </aside>
                </div>
              </div>
              <div className="col-12 mb-4">
                <h2 className="h3 mb-4">Retroalimentación y calificaciones</h2>
                {evaluations.map((item) => (
                  <div className="quality-score-info" key={item._id}>
                    <p>
                      <strong>
                        {item.userId.username || ''} -{' '}
                        {format(
                          new Date(item.createdAt || ''),
                          'HH:mm dd/MM/yyyy'
                        )}
                      </strong>
                    </p>
                    <div className="form-row">
                      <textarea
                        className="form-control form--filled"
                        placeholder="Reseña de usuario"
                        value={item.observations}
                        disabled
                      ></textarea>
                    </div>
                    <div className="quality-score-list">
                      {item.adminEvaluationTopics.map((typeItem, index) => (
                        <StarScore
                          key={index}
                          type={typeItem.topic}
                          score={typeItem.score}
                        />
                      ))}
                    </div>
                    <hr />
                  </div>
                ))}
              </div>
              <div className="col-12 d-flex justify-content-center">
                <a
                  href="#"
                  className="btn btn--type2"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  Regresar al perfil
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default EvaluationAdminScreen;
